<header class="header_404">
  <div class="container">
    <a class="wrap_logo" [routerLink]="['/']">
      <img src="/assets/images/logos/opencloud-white.svg" alt="opencloud">
    </a>
  </div>
</header>

<section class="main_404">
  <div class="wrapper">
    <div class="inner">
      <picture>
        <source srcset="/assets/images/error-page/img-error-page.svg" media="(min-width: 992px)">
        <source srcset="/assets/images/error-page/img-error-page-tablet.svg" media="(min-width: 768px)">
        <img src="/assets/images/error-page/img-error-page-mobile.svg" alt="door">
      </picture>
      <div class="wrapTxt">
        <div class="title" i18n>Oops!</div>
        <div class="error">404</div>
        <p i18n>Página no encontrada</p>
        <a [routerLink]="['/']" i18n>volver al home</a>
      </div>
    </div>
  </div>
</section>
