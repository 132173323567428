import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { MetaService } from '@ngx-meta/core';

import { INoResult } from './no-search-result/no-search-result.component';
import { URL } from '../../environments/environment';

@Component({
    selector: 'app-direct-payment',
    templateUrl: './direct-payment.component.html',
    styleUrls: ['./direct-payment.component.scss']
})
export class DirectPaymentComponent implements OnInit {
    public dataNoResult: INoResult;
    public dataResult;
    public isLoading: boolean;
    public isNoResult: boolean;
    public isComplete: boolean;

    public notRegister: { 'error': INoResult, 'badInput': INoResult } = {
        'error': {
            icon: 'icon-sprite-close-circle',
            title: 'No se encontraron resultados',
            text: 'Lo sentimos! no se encontraron resultados, por favor vuelve a intentarlo más tarde.',
            state: 'error',
            type: 'notRegister'
        },
        'badInput': {
            icon: 'icon-sprite-close-circle',
            title: 'No se encontraron resultados',
            text: 'Los datos ingresados no corresponden a un Dominio, un Correo electrónico o un N° de Orden. Por favor verifica la información ingresada.',
            state: 'error',
            type: 'notRegister'
        }
    }

    public noResult: INoResult = {
        icon: 'icon-sprite-check-circle',
        title: 'Cliente no registra deuda',
        text: 'Si necesitas más información o requieres resolver dudas técnicas o comerciales visita:',
        type: 'noResult'
    }

    public URL = URL;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private readonly _meta: MetaService
    ) { }

    ngOnInit(): void {
        this._scrollTop();
        this._setMeta();
    }

    public getLoadingStatus(ev): void {
        this.isLoading = ev;
    }

    public getNoResultVisibility(ev): void {
        this.isNoResult = ev.show;
        if (ev.status === 'No found') {
            this.dataNoResult = this.noResult;
        } else {
            this.dataNoResult = this.notRegister[ev.status];
        }
    }

    public getCompleteStatus(ev): void {
        this.isComplete = ev.show;
        this.dataResult = ev;
    }

    private _scrollTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }

    private _setMeta() {
        this._meta.setTag('author', 'OpenCloud');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.OPENCLOUD_WEBSITE + '/assets/images/og/og-pago-directo.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
    }
}
