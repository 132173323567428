<section class="sectionSearchResult">
  <div class="sectionSearchResult__container">

    <h2 id="resultTable" class="sectionSearchResult__title center" i18n>
      Resultados de tu búsqueda
    </h2>

    <!-- START searchResult -->
    <table class="searchResult" aria-describedby="resultTable" *ngIf="!showAlertMessage">

      <thead class="searchResult__thead">
        <tr>
          <ng-container *ngFor="let col of dataResult.header; let i = index">
            <th class="searchResult__th accent-primary"  *ngIf="i === 0" i18n>
              {{col}}
            </th>
            <th class="searchResult__th accent-primary" *ngIf="i !== 0" i18n>
              {{col}}
            </th>
          </ng-container>
        </tr>
      </thead>

      <tbody class="searchResult__tbody">
        <tr class="searchResult__tr" *ngFor="let row of dataResult.body">

          <td class="searchResult__td" *ngFor="let col of row.columns; let i = index" >
            <b class="title accent-primary" *ngIf="dataResult.header[i].length > 0" i18n>{{dataResult.header[i]}}</b>
            <ng-container *ngIf="col.text">
              <div class="text" i18n [innerHTML]="col.text"></div>
            </ng-container>
            <span *ngIf="col.code">#{{col.code}}</span>
            <span *ngIf="col.value">
              <b class="value">{{col.value}}</b> <br>
            </span>
            <span *ngIf="col.tax" i18n>{{col.tax}} incluido</span>
            <ng-container class="flex_column flex_end" [ngSwitch]="linkType">
              <div *ngSwitchCase="'both'">
                <div class="flex_buttons">
                  <button
                    mat-button
                    [id]="col.buttonDebit.id"
                    class="btn btnTranspBlue"
                    (click)="navigateTo(col.buttonDebit.href)"
                    *ngIf="col.buttonDebit"
                    i18n>
                    Débito o Crédito
                  </button>
                  <button
                    mat-button
                    [id]="col.buttonTransfer.id"
                    class="btn btnTranspBlue"
                    (click)="navigateTo(col.buttonTransfer.href)"
                    *ngIf="col.buttonTransfer"
                    i18n>
                    Transferencia
                  </button>
                </div>
              </div>
              <div *ngSwitchCase="'single'">
                <button
                  mat-button
                  [id]="col.buttonPaymentOthers.id"
                  class="btn btnTranspBlue rounded upper"
                  (click)="navigateTo(col.buttonPaymentOthers.href)"
                  *ngIf="col.buttonPaymentOthers"
                  i18n>
                  Ver orden
                </button>
              </div>
              <div *ngSwitchCase="'none'">
                <p *ngIf="col.buttonPaymentOthers || col.buttonDebit || col.buttonTransfer">
                  Lo sentimos, no se ha encontrado un link de pago
                </p>
              </div>
            </ng-container>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- END searchResult -->

    <!-- START notification -->
    <div class="notification error" *ngIf="showAlertMessage">
      <svg-icon-sprite
        [src]="'/assets/images/sprites/sprite.svg#icon-sprite-comment-exclamation'"
        [width]="'40px'">
      </svg-icon-sprite>
      <p i18n>
        Lo sentimos! sólo encontramos ordenes impagas de servicios que ya están terminados, si gustas puedes contratar un nuevo producto o servicio.
      </p>
    </div>
    <!-- END notification -->

  </div>
</section>
