import { CONTACTS } from '../../../../../common/contacts.constants';
import { Contact } from '../../../../../common/Contact';
import { Component, OnInit, HostListener, Inject, PLATFORM_ID, ViewChild, ElementRef } from '@angular/core';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { DialogCustomMenuComponent } from '../dialogs/dialog-custom-menu/dialog-custom-menu.component';
import { Router } from '@angular/router';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})

export class HeaderComponent implements OnInit {
    @ViewChild('header', { static: true }) header: ElementRef;
    @ViewChild('triggerDialog', { static: true }) triggerDialog: ElementRef;

    isMobile: boolean;
    isActiveDropdownSupport: boolean;

    contactList: Contact[];

    @HostListener('window:scroll')

    onScroll(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.checkHeaderPosition();
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.isMobile = window.innerWidth > 670 ? true : false;
    }

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        @Inject(DOCUMENT) private _document: Document,
        public dialog: MatDialog,
        private _router: Router
    ) { }

    ngOnInit() {
        this.contactList = CONTACTS.filter(c => c.country_code === 'cl');
        this.isMobile = window.innerWidth > 670 ? true : false;
    }

    checkHeaderPosition(): void {
        if (isPlatformBrowser(this.platformId)) {
            const offsetY = window.scrollY;
            const headerEl = this.header.nativeElement;
            if (offsetY >= 50 || this._document.getElementsByTagName('html')[0].classList.contains('cdk-global-scrollblock')) {
                headerEl.classList.add('fixed');
            } else {
                headerEl.classList.remove('fixed');
            }
        }
    }

    scrollTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    onClickOpenMenu() {
        const body = this._document.getElementsByTagName('body')[0];
        body.classList.add('open_menu');
    }

    onClickCloseMenu() {
        const body = this._document.getElementsByTagName('body')[0];
        body.classList.remove('open_menu');
        this.isActiveDropdownSupport = false;
    }

    openDialogCustomMenu() {
        if (window.innerWidth > 670) {
            this.dialog.open(DialogCustomMenuComponent, {
                panelClass: 'dialogCustomMenu',
                width: '268px',
                backdropClass: 'transparent',
                position: {
                    top: '90px',
                    left: this.triggerDialog.nativeElement.getBoundingClientRect().right - 210 + 'px'
                }
            });
        }
    }

    // open dropdown menu of the mobile
    openDropdownMenu(menu) {
        if (menu === 'soporte') {
            this.isActiveDropdownSupport = true;
        }
    }

    // close dropdown menu of the mobile
    closeDropdownMenu(menu) {
        if (menu === 'soporte') {
            this.isActiveDropdownSupport = false;
        }
    }

    getClassHeader() {
        if (this._router.url === '/contactar-con-ventas/.' || this._router.url === '/contactar-con-soporte/.') {
            return 'non-sticky';
        }
    }
}
