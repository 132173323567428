<ng-container *ngIf="isShowVideo">
  <div #videoHolder class="video_holder" [class.show-video]="isShowVideo">
    <button class="btnClose" mat-icon-button (click)="hideVideo()">
      <mat-icon>close</mat-icon>
    </button>
    <video #serverVideo src="/assets/video/server-video.mp4" preload="metadata" playsinline></video>
  </div>
</ng-container>

<!-- START aboutProduct v3 -->
<section class="aboutProduct v3 v3-1">
  <div class="container">
    <div class="wrapIcon w-60 m-auto rose">
      <div class="icon color-white">
        <svg-icon-sprite [src]="'/assets/images/sprites/sprite.svg#icon-sprite-realtime'" [width]="'32px'" [classes]="'icon-sprite-realtime'">
        </svg-icon-sprite>
      </div>
    </div>
    <div class="sup_title upper center" i18n>Panel de control</div>
    <h2 class="title2 center" i18n><b>Control total</b> de tu SSD Cloud Server</h2>
  </div>

  <div class="wrapper">
    <div class="aboutProduct__img">
      <picture>
        <source srcset="/assets/images/features/panel/panel-control@2x.webp 2x" type="image/webp" media="(min-width: 993px)">
        <source srcset="/assets/images/features/panel/panel-control@1x.png,
                        /assets/images/features/panel/panel-control@2x.png 2x" media="(min-width: 993px)">
        <source srcset="/assets/images/features/panel/panel-control-tablet@2x.webp 2x" type="image/webp">
        <img src="/assets/images/features/panel/panel-control-tablet@1x.png" srcset="/assets/images/features/panel/panel-control-tablet@2x.png 2x" alt="Panel de control">
      </picture>
    </div>

    <div class="aboutProduct__info">
      <div class="inner">
        <h3 class="title3" i18n>Panel de Control</h3>
        <p i18n>Toma el control total de tu servidor y administra como desees hasta el más mínimo detalle de tu SSD Cloud Server.</p>
        <h3 class="title3" i18n>Realiza Upgrades</h3>
        <p i18n>Nuestros servidores virtuales se adaptan a tus necesidades. Desde tu panel de control podrás contratar upgrades a tu server cuando lo desees.</p>
        <h3 class="title3" i18n>Modo Rescate</h3>
        <p i18n>Salva tu trabajo y tus archivos a través del modo de rescate para revertir y solucionar problemas.</p>
        <h3 class="title3" i18n>Estadísticas tiempo real</h3>
        <p i18n>Monitorea el comportamiento de tu SSD Cloud Server con estadísticas en vivo.</p>
        <h3 class="title3" i18n [preRender]="false" (deferLoad)="showImage1 = true">Acceso vía consola</h3>
        <p i18n>Accede, configura y maneja libremente tu SSD Cloud Server desde tu consola.</p>
      </div>
    </div>
  </div>
</section>
<!-- END aboutProduct v3 -->

<!-- START aboutProduct v3 -->
<section class="aboutProduct v3">
  <div class="container">
    <div class="wrapper justify-center">
      <div class="aboutProduct__info">
        <div class="inner">
          <div class="wrapIcon w-60 blue">
            <ng-container *ngIf="showImage1"><!-- showImage1 because we need render before. -->
              <div class="icon color-white">
                <svg-icon-sprite [src]="'/assets/images/sprites/sprite.svg#icon-sprite-hard-drives'" [width]="'32px'" [classes]="'icon-sprite-hard-drives'">
                </svg-icon-sprite>
              </div>
            </ng-container>
          </div>
          <div class="sup_title upper" i18n>Discos SSD Sólidos nativos</div>
          <h2 class="title2" i18n><b>Más velocidad</b> para tu Server</h2>
          <h3 class="title3" i18n>300% más rápidos</h3>
          <p i18n>Obtén una mayor velocidad de lectura, escritura y transferencia de archivos.</p>
          <h3 class="title3" i18n>Mayor confiabilidad</h3>
          <p i18n>Los discos sólidos no tienen partes móviles que se desgasten con el uso y puede soportar más golpes y vibraciones.</p>
          <h3 class="title3" i18n>Nativos</h3>
          <p i18n>Los discos nativos no tiene que pasar por otro medio como red para obtener los datos, reduciendo así sus tiempos de carga.</p>
        </div>
      </div>

      <div class="aboutProduct__info">
        <!-- START boxInfo -->
        <div class="boxInfo">
          <div class="boxInfo__item">
            <span class="title upper" i18n>INTERFACE</span>
            <span class="sub">SAS</span>
            <span class="count">12</span>
            <span class="sub">Gb/s</span>
          </div>

          <div class="boxInfo__item">
            <span class="title upper" i18n>escritura</span>
            <span class="count">850</span>
            <span class="sub">MIB/s</span>
          </div>

          <div class="boxInfo__item">
            <span class="title upper" i18n>LECTURA</span>
            <span class="count">1900</span>
            <span class="sub">MIB/s</span>
          </div>
        </div>
        <!-- END boxInfo -->
      </div>
    </div>
  </div>
</section>
<!-- END aboutProduct v3 -->

<!-- START aboutProduct v3 -->
<section class="aboutProduct v3" [preRender]="false" (deferLoad)="showImage2 = true">
  <div class="wrapper">
    <div class="aboutProduct__img">
      <ng-container *ngIf="showImage1"><!-- showImage1 because we need render before. -->
        <picture>
          <source srcset="/assets/images/features/server/server-dell-desktop@1x.avif,
                          /assets/images/features/server/server-dell-desktop@2x.avif 2x" type="image/avif" media="(min-width: 670px)">
          <source srcset="/assets/images/features/server/server-dell-desktop@1x.webp,
                          /assets/images/features/server/server-dell-desktop@2x.webp 2x" type="image/webp" media="(min-width: 670px)">
          <source srcset="/assets/images/features/server/server-dell-desktop@1x.png,
                          /assets/images/features/server/server-dell-desktop@2x.png 2x" media="(min-width: 670px)">
          <source srcset="/assets/images/features/server/server-dell-mobile@1x.webp,
                          /assets/images/features/server/server-dell-mobile@2x.webp 2x" type="image/webp">
          <img src="/assets/images/features/server/server-dell-mobile@1x.png" srcset="/assets/images/features/server/server-dell-mobile@2x.png 2x" alt="SERVIDORES DELL R920">
        </picture>
      </ng-container>
    </div>

    <div class="aboutProduct__info">
      <div class="inner">
        <div class="wrapIcon w-60 rose">
          <ng-container *ngIf="showImage1"><!-- showImage1 because we need render before. -->
            <div class="icon color-white">
              <svg-icon-sprite [src]="'/assets/images/sprites/sprite.svg#icon-sprite-proc'" [width]="'32px'" [classes]="'icon-sprite-proc'">
              </svg-icon-sprite>
            </div>
          </ng-container>
        </div>
        <div class="sup_title upper" i18n>SERVIDORES DELL R920</div>
        <h2 class="title2" i18n>Servidores de <b>alto rendimiento</b></h2>
        <h3 class="title3" i18n>Servidores Dell R920</h3>
        <p i18n>Contamos con Servidores Dell R920 constituidos solo con partes certificadas y originales enterprise para entregar el mayor rendimiento posible.</p>
        <h3 class="title3" i18n>Procesador XEON E7</h3>
        <p i18n>Nuestro poderoso procesador Intel Xeon CPU E7-4890 permite la gestión de grandes volúmenes de datos y cuenta con funciones de seguridad, fiabilidad y rendimiento.</p>
        <h3 class="title3" i18n>Discos en RAID 10</h3>
        <p i18n>Protegemos tu información en varios discos duros independientes de manera redundante entregando así 2 veces más velocidad de lectura.</p>
      </div>
    </div>
  </div>
</section>
<!-- END aboutProduct v3 -->

<!-- START aboutProduct v3 -->
<section class="aboutProduct aboutProduct_services v3 p-38" [preRender]="false" (deferLoad)="showImage3 = true">
  <div class="wrapper reverse">
    <div class="aboutProduct__info">
      <div class="inner">
        <div class="wrapIcon w-60 blue">
          <ng-container *ngIf="showImage1"><!-- showImage1 because we need render before. -->
            <div class="icon color-white">
              <svg-icon-sprite [src]="'/assets/images/sprites/sprite.svg#icon-sprite-bell-count'" [width]="'32px'" [classes]="'icon-sprite-bell-count'">
              </svg-icon-sprite>
            </div>
          </ng-container>
        </div>
        <div class="sup_title upper" i18n>MONITOREO Y SERVICIOS DNS</div>
        <h2 class="title2" i18n>Recibe<b> alertas vía SMS y correo</b></h2>
        <h3 class="title3" i18n>Sistema monitoreo y alerta personalizado</h3>
        <p i18n>Puedes personalizar los monitoreos a los servicios de tu SSD Cloud Server y recibir las alertas vía SMS o correo electrónico.</p>
        <h3 class="title3" i18n>Servicio DNS personalizado</h3>
        <p i18n>Desde tu panel de control podrás definir tus DNS en pocos segundos.</p>
      </div>
    </div>

    <div class="aboutProduct__img">
      <ng-container *ngIf="showImage2"><!-- showImage2 because we need render before. -->
        <picture>
          <source srcset="/assets/images/features/services/receive-sms-desktop@2x.webp 2x" type="image/webp" media="(min-width: 671px)">
          <source srcset="/assets/images/features/services/receive-sms-desktop@1x.png,
                          /assets/images/features/services/receive-sms-desktop@2x.png 2x" media="(min-width: 671px)">
          <source srcset="/assets/images/features/services/receive-sms-mobile@1x.webp,
                          /assets/images/features/services/receive-sms-mobile@2x.webp 2x" type="image/webp">
          <img src="/assets/images/features/services/receive-sms-mobile@1x.png" srcset="/assets/images/features/services/receive-sms-mobile@2x.png 2x" alt="Recibe alertas vía SMS y correo">
        </picture>
      </ng-container>
    </div>
  </div>
</section>
<!-- END aboutProduct v3 -->

<!-- START textColumn -->
<section class="textColumn__wrapper bg_light2" [class.bottom-image]="showImage3" [preRender]="false" (deferLoad)="showImage4 = true">
  <div class="container">
    <div class="wrapIcon w-60 blue m-auto">
      <ng-container *ngIf="showImage2"><!-- showImage2 because we need render before. -->
        <div class="icon color-white">
          <svg-icon-sprite [src]="'/assets/images/sprites/sprite.svg#icon-sprite-connectivity'" [width]="'32px'" [classes]="'icon-sprite-connectivity'">
          </svg-icon-sprite>
        </div>
      </ng-container>
    </div>
    <div class="sup_title upper center" i18n>Conectividad nacional e internacional</div>
    <h2 class="title2 center" i18n>Enlaces con <b>autonomía y redundancia</b></h2>

    <div class="textColumn">
      <div class="textColumn__column">
        <h3 class="title3" i18n>Conectividad con Uptime de 99.9%</h3>
        <p i18n>Porque nuestro mayor valor es el compromiso con nuestro clientes, garantizamos el 99.9% de Uptime de nuestra
          conectividad.</p>
        <h3 class="title3" i18n>Fiabilidad en la conexión: 3 Fibras, 3 Rutas</h3>
        <p i18n>Contamos con enlaces totalmente independientes que pertenecen a tres proveedores distintos que son distribuidos por tres rutas diferentes para garantizar total autonomía y redundancia.</p>
      </div>
      <div class="textColumn__column">
        <h3 class="title3" i18n>Conectividad directa con los países de Latinoamérica</h3>
        <p i18n>A diferencia de la mayoría de servidores nuestra rutas de conexión conectan Latinoamérica directamente sin pasar por
          Miami, bajando hasta 120 ms la latencia.</p>
        <h3 class="title3" i18n>2 Conexiones con Tier I</h3>
        <p i18n>En OpenCloud tenemos dos enlaces dedicados de nivel 1 de las empresas Internexa y CenturyLink.</p>
      </div>
    </div>
  </div>
</section>
<!-- END textColumn -->

<!-- START aboutProduct v3 -->
<section class="aboutProduct v3 bg_light aboutProduct_sistem" [preRender]="false" (deferLoad)="showImage5 = true">
  <div class="wrapper reverse">
    <div class="aboutProduct__info">
      <div class="inner">
        <div class="wrapIcon w-60 blue">
          <ng-container *ngIf="showImage2"><!-- showImage2 because we need render before. -->
            <div class="icon color-white">
              <svg-icon-sprite [src]="'/assets/images/sprites/sprite.svg#icon-sprite-headset'" [width]="'32px'" [classes]="'icon-sprite-headset'">
              </svg-icon-sprite>
            </div>
          </ng-container>
        </div>
        <div class="sup_title upper" i18n>Sistema operativo</div>
        <h2 class="title2" i18n><b>Windows/Linux</b></h2>
        <p i18n>Despliega tu SSD Cloud Server con el sistema operativo que prefieras: Windows o Linux Ubuntu, Debian, Centos o Fedora. Las licencias de sistemas operativos no están incluidas.</p>
      </div>
    </div>

    <div class="aboutProduct__img">
      <ng-container *ngIf="showImage3"><!-- showImage3 because we need render before. -->
        <picture>
          <source srcset="/assets/images/features/os/os-desktop@1x.avif,
                          /assets/images/features/os/os-desktop@2x.avif 2x" type="image/avif" media="(min-width: 671px)">
          <source srcset="/assets/images/features/os/os-desktop@1x.webp,
                          /assets/images/features/os/os-desktop@2x.webp 2x" type="image/webp" media="(min-width: 671px)">
          <source srcset="/assets/images/features/os/os-desktop@1x.png,
                          /assets/images/features/os/os-desktop@2x.png 2x" media="(min-width: 671px)">
          <source srcset="/assets/images/features/os/os-mobile@1x.webp,
                          /assets/images/features/os/os-mobile@2x.webp 2x" type="image/webp">
          <img src="/assets/images/features/os/os-mobile@1x.png" srcset="/assets/images/features/os/os-mobile@2x.png 2x" alt="Sistema operativo">
        </picture>
      </ng-container>
    </div>
  </div>
</section>
<!-- END aboutProduct v3 -->

<!-- START blockSupport -->
<section class="blockSupport__wrapper" [preRender]="false" (deferLoad)="showFooter = true">
  <div class="container">
    <div class="wrapIcon w-60 m-auto rose">
      <ng-container *ngIf="showImage3"><!-- showImage3 because we need render before. -->
        <div class="icon color-white">
          <svg-icon-sprite [src]="'/assets/images/sprites/sprite.svg#icon-sprite-headset'" [width]="'32px'" [classes]="'icon-sprite-headset'">
          </svg-icon-sprite>
        </div>
      </ng-container>
    </div>
    <div class="sup_title upper center" i18n>SOPORTE</div>
    <h2 class="title2 center" i18n>Estamos para <b>ayudarte</b></h2>
    <p class="center" i18n>Nuestro equipo de expertos están comprometidos con solucionar tus dudas y problemas. Obtén un mejor tiempo de respuesta durante nuestro horario de oficina 9:00hrs - 19:00hrs (UTC-4)</p>

    <div class="blockSupport">
      <div class="blockSupport__item" *ngFor="let item of content.supportList">
        <ng-container *ngIf="showImage4"><!-- showImage4 because we need render before. -->
          <div class="icon accent">
            <svg-icon-sprite [src]="'/assets/images/sprites/sprite.svg#' + item.icon" [width]="'32px'" [classes]="item.icon">
            </svg-icon-sprite>
          </div>
        </ng-container>
        <span class="support" i18n>{{item.text}}</span>
        <span class="info">{{item.count}} <span i18n>{{item.unit}}</span></span>
        <span class="sub" i18n>Tiempo respuesta</span>
      </div>

    </div>
  </div>

  <ng-container *ngIf="showImage5"><!-- showImage5 because we need render before. -->
    <picture>
      <source srcset="/assets/images/features/support/bg-support-block-desktop@1x.webp,
                      /assets/images/features/support/bg-support-block-desktop@2x.webp 2x" type="image/webp" media="(min-width: 992px)">
      <source srcset="/assets/images/features/support/bg-support-block-desktop@1x.png,
                      /assets/images/features/support/bg-support-block-desktop@2x.png 2x" media="(min-width: 992px)">
      <source srcset="/assets/images/features/support/bg-support-block-tablet@1x.webp,
                      /assets/images/features/support/bg-support-block-tablet@2x.webp 2x" type="image/webp" media="(min-width: 481px)">
      <source srcset="/assets/images/features/support/bg-support-block-tablet@1x.png,
                      /assets/images/features/support/bg-support-block-tablet@2x.png 2x" media="(min-width: 481px)">
      <source srcset="/assets/images/features/support/bg-support-block-mobile@1x.webp,
                      /assets/images/features/support/bg-support-block-mobile@2x.webp 2x" type="image/webp">
      <img src="/assets/images/features/support/bg-support-block-mobile@1x.png" srcset="/assets/images/features/support/bg-support-block-mobile@2x.png 2x" alt="support">
    </picture>
  </ng-container>
</section>
<!-- END blockSupport -->

<!-- START aboutProduct v3 -->
<section class="aboutProduct v3 p-97" [class.bg-server]="showImage5">
  <div class="container">
    <div class="wrapper">
      <div class="aboutProduct__info">
        <div class="inner">
          <div class="wrapIcon w-60 rose">
            <ng-container *ngIf="showImage3"><!-- showImage3 because we need render before. -->
              <div class="icon color-white">
                <svg-icon-sprite [src]="'/assets/images/sprites/sprite.svg#icon-sprite-data-center'" [width]="'32px'" [classes]="'icon-sprite-data-center'">
                </svg-icon-sprite>
              </div>
            </ng-container>
          </div>
          <h2 class="title2 color-white" i18n>Data Center <b>en Chile</b></h2>
          <h3 class="title3 color-white" i18n>Data Center Propio</h3>
          <p class="color-white-light" i18n>Contamos con nuestro propio Data Center con doble UPS, generador con 12 horas de autonomía y climatización redundante.</p>
          <h3 class="title3 color-white" i18n>Ubicado en Chile</h3>
          <p class="color-white-light" i18n>Nuestra cercanía geográfica nos permite entregar una baja redundancia a nuestros clientes.</p>
        </div>
      </div>

      <button id="o-cd-video" class="btnPlay big" mat-icon-button (click)="playVideo()">
        <span class="text" i18n>Click para ver</span>
      </button>
    </div>
  </div>
</section>
<!-- END aboutProduct v3 -->

<ng-container *ngIf="showFooter"><!-- showFooter because we need render before. -->
  <app-footer></app-footer>
</ng-container>