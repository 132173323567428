<section class="block_logo">
  <div class="container">
    <div class="container_link">
      <button class="btn btn_blue" mat-button i18n (click)="goToUrl('https://www.haulmer.com')">Conoce nuestras marcas</button>
      <picture>
        <source srcset="/assets/images/logos/barra-desktop.svg" media="(min-width: 992px)">
        <source srcset="/assets/images/logos/barra-tablet.svg" media="(min-width: 670px)">
        <img src="/assets/images/logos/barra-mobile.svg" alt="logos">
      </picture>
    </div>
  </div>
</section>
