import { Component, OnInit, Input, Inject, HostListener, PLATFORM_ID, HostBinding } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-chart-speed2',
    templateUrl: './chart-speed2.component.html'
})
export class ChartSpeed2Component implements OnInit {
    @HostBinding('class') classList = 'chartWrapper chartWrapper2';
    @Input() endValue;
    @Input() maxValue = 100;

    chartsIsAnimated: boolean = false;

    ctx;
    x;
    y;
    radius;
    start;
    end;
    value;
    pi;
    canvas;
    curPerc = 0;
    curPerc2 = 0;
    circ;
    totalArcLength;

    @HostListener('window: scroll')
    onScroll() {
        this.playAnimateChart();
    }

    constructor(
        @Inject(DOCUMENT) private _document: Document,
        @Inject(PLATFORM_ID) private platformId: any,
    ) { }

    ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            this.initCanvas();
        }
    }

    isInViewport(elem) {
        const viewportWindow = window.innerHeight || this._document.documentElement.clientHeight;
        const elemRect = elem.getBoundingClientRect();
        return (elemRect.top >= 104 && elemRect.bottom <= viewportWindow);
    }

    playAnimateChart() {
        const sectionCanvas = <HTMLElement>this._document.getElementsByClassName('canvasSpeed')[0];


        if (this.isInViewport(sectionCanvas) && !this.chartsIsAnimated) {
            this.animate();
            this.chartsIsAnimated = true;
        }
    }

    initCanvas() {
        this.canvas = <HTMLCanvasElement>this._document.getElementById('canvasSpeed2');
        this.ctx = this.canvas.getContext('2d');
        this.pi = Math.PI;
        this.x = this.canvas.width / 2;
        this.y = this.canvas.height / 2;
        this.radius = this.canvas.width / 2 - 17;
        this.circ = this.pi * 2;
        this.start = this.pi * 1.3; // Start position
        this.end = this.pi * 3.5; // End position
        this.value = 0.000;
        this.totalArcLength = this.pi * 2;
    }

    // START setTitleChart
    setTitleChart() {
        this.ctx.beginPath();
        this.ctx.font = 'bold 21px Mukta';
        this.ctx.fillStyle = '#050D40';
        this.ctx.textAlign = 'center';
        this.ctx.textBaseline = 'middle';
        this.ctx.fillText('Linode', this.x, this.canvas.width / 2 - 20);
    }

    // START setTitlePointChart1
    setTitlePointChart1() {
        this.ctx.beginPath();
        this.ctx.font = 'bold 9px Mukta';
        this.ctx.fillStyle = 'rgba(5,13,64,.8)';
        this.ctx.textAlign = 'right';
        this.ctx.textBaseline = 'middle';
        this.ctx.fillText('CPU', this.x - 3, this.canvas.width / 2 + 3);
    }

    // START setTextPointChart1
    setTextPointChart1() {
        this.ctx.beginPath();
        this.ctx.font = '9px Mukta';
        this.ctx.fillStyle = '#030D40';
        this.ctx.textAlign = 'left';
        this.ctx.textBaseline = 'middle';
        this.ctx.fillText('-42%', this.x + 3, this.canvas.width / 2 + 3);
    }

    // START setTitlePointChart2
    setTitlePointChart2() {
        this.ctx.beginPath();
        this.ctx.font = 'bold 9px Mukta';
        this.ctx.fillStyle = 'rgba(5,13,64,.8)';
        this.ctx.textAlign = 'right';
        this.ctx.textBaseline = 'middle';
        this.ctx.fillText('IO', this.x - 3, this.canvas.width / 2 + 15);
    }

    // START setTextPointChart2
    setTextPointChart2() {
        this.ctx.beginPath();
        this.ctx.font = '9px Mukta';
        this.ctx.fillStyle = '#050D40';
        this.ctx.textAlign = 'left';
        this.ctx.textBaseline = 'middle';
        this.ctx.fillText('-83,9%', this.x + 3, this.canvas.width / 2 + 15);
    }

    // START setTitlePointChart3
    setTitlePointChart3() {
        this.ctx.beginPath();
        this.ctx.font = 'bold 9px Mukta';
        this.ctx.fillStyle = 'rgba(5,13,64,.8)';
        this.ctx.textAlign = 'right';
        this.ctx.textBaseline = 'middle';
        this.ctx.fillText('TRÁFICO', this.x - 3, this.canvas.width / 2 + 28);
    }

    // START setTextPointChart3
    setTextPointChart3() {
        this.ctx.beginPath();
        this.ctx.font = '9px Mukta';
        this.ctx.fillStyle = '#050D40';
        this.ctx.textAlign = 'left';
        this.ctx.textBaseline = 'middle';
        this.ctx.fillText('-26,3%', this.x + 3, this.canvas.width / 2 + 28);
    }

    // START progress
    progress(current) {
        this.ctx.beginPath();
        this.ctx.lineWidth = 12;
        this.ctx.strokeStyle = '#1633FF';
        this.ctx.arc(this.x, this.y, this.radius, this.start, ((this.totalArcLength * current) + this.start), false);
        this.ctx.stroke();
    }

    animate(current?) {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.ctx.beginPath();
        this.setTitleChart();
        this.setTitlePointChart1();
        this.setTextPointChart1();
        this.setTitlePointChart2();
        this.setTextPointChart2();
        this.setTitlePointChart3();
        this.setTextPointChart3();
        this.progress(current);
        this.curPerc++;

        if (this.curPerc < this.endValue + 1) {
            requestAnimationFrame(() => {
                this.animate(this.curPerc / this.maxValue);
            });
        }
    }
}
