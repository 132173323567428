<ul class="customDropdownMenu">
  <li>
    <button id="oc-support" class="customDropdownMenu__item" mat-button (click)="goToUrl('contactar-con-soporte')" tabindex="-1">
      <i class="icon icon_support"></i>Contactar Soporte
    </button>
  </li>
  <li>
    <button id="oc-support-doc" class="customDropdownMenu__item" mat-button tabindex="-1" (click)="goToUrl('https://help.haulmer.com/hc/cloud-server')">
      <i class="icon icon_doc"></i> Documentación
    </button>
  </li>
  <li>
    <button id="oc-support-suggestions" class="customDropdownMenu__item" mat-button tabindex="-1" (click)="goToUrl('https://survey.typeform.com/to/zQ7I32J9#empresa=Opencloud&issuetype=11207')">
      <i class="icon icon_suggestions"></i> Sugerencias o reclamos
    </button>
  </li>
  <!-- <li>
    <button class="customDropdownMenu__item" mat-button tabindex="-1">
      <i class="icon icon_status_service"></i>Status de Servicios
    </button>
  </li> -->
</ul>
