import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import { Injectable } from '@angular/core';


@Injectable()
export class SuccessGuard implements CanActivate {

  constructor() {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (route.queryParamMap.has('total') && route.queryParamMap.has('ticket') && route.queryParamMap.has('validator')){
      return true;
    }
    return false;
  }
}