<button
    mat-icon-button
    tabindex="-1"
    mat-dialog-close
    class="btnClose"
    aria-label="Cerrar mensaje de promoción"
>
    <svg-icon-sprite
        [src]="'icon-sprite-close'"
        [width]="'30px'"
        aria-hidden="true"
    >
    </svg-icon-sprite>
</button>
<div class="wrapper">
    <div class="head">
        <span class="{{dataModal.title.style}}" [innerHTML]="dataModal.title.text">
            <svg-icon-sprite *ngIf="dataModal.title.icon" [src]="'icon-smile-circle'" [width]="logoWidth">
            </svg-icon-sprite>
        </span>
        <p class="interTitle">{{dataModal.interTitle}} </p>
        <p class="{{dataModal.subtitle.style}}" [innerHTML]="dataModal.subtitle.text">
        </p>
    </div>

    <div class="body" *ngIf="dataModal.promoCode">
        <span [ngClass]="dataModal.footer.style">Usando el código:</span>
        <div class="promoCodeBox {{ dataModal.promoCode.borderStyle }}">
            <b class="{{ dataModal.promoCode.style }}">
                {{ dataModal.promoCode.code }}
            </b>
        </div>
    </div>
    <div class="actions">
        <ng-container *ngIf="appType !== 'TUU'; else tuu">
            <button [id]="dataModal.button.buttonId" mat-button (click)="useButton($event)" class="{{dataModal.button.style}}">
                {{ dataModal.button.text }}
            </button>
        </ng-container>
        <ng-template #tuu>
            <ng-container *ngIf="dataModal.button.type==='mat-button'; else ripple">
                <button mat-button class="btn btnFillPrimary btn_md upper" [id]="dataModal.button.buttonId" [styleButton]="dataModal.button.style" (click)="useButton($event)">
                    {{ dataModal.button.text }}
                </button>
            </ng-container>
            <ng-template #ripple>
                <button matRipple class="btnAnimated" appAnimationButton [id]="dataModal.button.buttonId" [styleButton]="dataModal.button.style" (click)="useButton($event)">
                    <b><span>{{dataModal.button.text}}</span></b>
                </button>
            </ng-template>
        </ng-template>
    </div>

    <div class="foot" [ngClass]="dataModal.footer.style">
        <span [innerHTML]="dataModal.footer.text"></span>
    </div>
</div>
