import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import {
    style,
    animate,
    keyframes,
    AnimationPlayer,
    AnimationBuilder,
} from '@angular/animations';

interface IAnimationButton {
    startBackgroundColor: string;
    startTextColor: string;
    startBorderColor: string;
    endBackgroundColor: string;
    endTextColor: string;
    endBorderColor: string;
}

export const FILLED_PRIMARY_BUTTON: IAnimationButton = {
    startBackgroundColor: '#1633FF',
    startTextColor: '#fff',
    startBorderColor: '#1633FF',
    endBackgroundColor: '#00FFF8',
    endTextColor: '#1633FF',
    endBorderColor: '#00FFF8'
}

export const FILLED_SECONDARY_BUTTON: IAnimationButton = {
    startBackgroundColor: '#F17DFF',
    startTextColor: '#fff',
    startBorderColor: '#F17DFF',
    endBackgroundColor: '#00FFF8',
    endTextColor: '#1633FF',
    endBorderColor: '#00FFF8'
}

export const FILLED_THIRD_BUTTON: IAnimationButton = {
    startBackgroundColor: '#00FFF8',
    startTextColor: '#1633FF',
    startBorderColor: '#00FFF8',
    endBackgroundColor: 'transparent',
    endTextColor: '#00FFF8',
    endBorderColor: '#00FFF8',
}

export const OUTLINE_TRANSPARENT_BUTTON: IAnimationButton = {
    startBackgroundColor: 'transparent',
    startTextColor: '#1633FF',
    startBorderColor: '#1633FF',
    endBackgroundColor: '#1633FF',
    endTextColor: '#fff',
    endBorderColor: '#1633FF'
}

export const OUTLINE_FILLED_BUTTON: IAnimationButton = {
    startBackgroundColor: 'rgba(255,255,255,.2)',
    startTextColor: '#fff',
    startBorderColor: '#fff',
    endBackgroundColor: '#00FFF8',
    endTextColor: '#1633FF',
    endBorderColor: '#00FFF8'
}

export const OUTLINE_PRIMARY_FILLED_BUTTON: IAnimationButton = {
    startBackgroundColor: 'rgba(255,255,255,.2)',
    startTextColor: '#1633FF',
    startBorderColor: '#1633FF',
    endBackgroundColor: '#1633FF',
    endTextColor: '#fff',
    endBorderColor: '#1633FF'
}

export const FILLED_PROMOTIONAL_BUTTON: IAnimationButton = {
    startBackgroundColor: '#00fff8',
    startTextColor: '#000',
    startBorderColor: '#00fff8',
    endBackgroundColor: '#F17DFF',
    endTextColor: '#000',
    endBorderColor: '#fff'
}

export const OUTLINE_BLACK_BUTTON: IAnimationButton = {
    startBackgroundColor: 'transparent',
    startTextColor: '#000',
    startBorderColor: '#000',
    endBackgroundColor: '#1633FF',
    endTextColor: '#fff',
    endBorderColor: '#1633FF'
}

@Directive({
    selector: '[appAnimationButton]'
})
export class AnimationButtonDirective implements OnInit {
    @Input() public styleButton: string = 'filledPrimary';

    public buttonPlayer: AnimationPlayer | undefined;
    public textPlayer: AnimationPlayer | undefined;
    public colorAnimations: any = {};
    public transformAnimations: any = {};

    @HostListener('change') ngOnChanges() {
        this.initStylesButton();
        if (!this._el.nativeElement.disabled) {
            this._animateColors('end');
        }
    }

    @HostListener("mouseenter") onMouseEnter() {
        this._animateColors('start');
        this._animateText('start');
    }

    @HostListener("mouseleave") onMouseLeave() {
        this._animateColors('end');
        this._animateText('end');
    }

    constructor(
        private _el: ElementRef,
        private _builder: AnimationBuilder
    ) {}

    ngOnInit(): void {
        this.initStylesButton();
    }

    public initStylesButton(): void {
        this._el.nativeElement.style.backgroundColor = this._getStylesOptions(this.styleButton).startBackgroundColor;
        this._el.nativeElement.style.color = this._getStylesOptions(this.styleButton).startTextColor;
        this._el.nativeElement.style.borderColor = this._getStylesOptions(this.styleButton).startBorderColor;
    }

    private _getStylesOptions(style: string): IAnimationButton {
        switch (style) {
            case 'filledPrimary':
                return FILLED_PRIMARY_BUTTON;
            case 'filledSecondary':
                return FILLED_SECONDARY_BUTTON;
            case 'filledThird':
                return FILLED_THIRD_BUTTON;
            case 'outlineTransparent':
                return OUTLINE_TRANSPARENT_BUTTON;
            case 'outlineFilled':
                return OUTLINE_FILLED_BUTTON;
            case 'outlinePrimaryFilled':
                return OUTLINE_PRIMARY_FILLED_BUTTON;
            case 'promotionalBtn':
                return FILLED_PROMOTIONAL_BUTTON
            case 'outlineBlack':
                return OUTLINE_BLACK_BUTTON
            default:
                return FILLED_PRIMARY_BUTTON;
        }
    }

    private _animateColors(animationName: string): void {
        if (this.buttonPlayer) {
            this.buttonPlayer.destroy();
        }

        if (animationName === 'start') {
            this.colorAnimations[animationName] = animate('0.1s ease-in', keyframes([
                style({
                    color: this._getStylesOptions(this.styleButton).endTextColor,
                    backgroundColor: this._getStylesOptions(this.styleButton).endBackgroundColor,
                    borderColor: this._getStylesOptions(this.styleButton).endBorderColor
                }),
            ]));
        } else if (animationName === 'end') {
            this.colorAnimations[animationName] = animate('0.1s ease-in', keyframes([
                style({
                    color: this._getStylesOptions(this.styleButton).startTextColor,
                    backgroundColor: this._getStylesOptions(this.styleButton).startBackgroundColor,
                    borderColor: this._getStylesOptions(this.styleButton).startBorderColor
                }),
            ]));
        }

        const buttonPlayer = this._builder.build(this.colorAnimations[animationName]).create(this._el.nativeElement);
        buttonPlayer.play();
    }

    private _animateText(animationName: string): void {
        if (this.textPlayer) {
            this.textPlayer.destroy();
        }

        if (animationName === 'start') {
            this.transformAnimations[animationName] = animate('1.5s ease-out', keyframes([
                style({ transform: 'translateY(-20px)' }),
                style({ transform: 'translateY(10px)' }),
                style({ transform: 'translateY(-5px)' }),
                style({ transform: 'translateY(5px)' }),
                style({ transform: 'translateY(-5px)' }),
                style({ transform: 'translateY(0)' })
            ]));
        } else if (animationName === 'end') {
            this.transformAnimations[animationName] = animate('1.5s ease-out', keyframes([
                style({ transform: 'translateY(5px)' }),
                style({ transform: 'translateY(-20px)' }),
                style({ transform: 'translateY(10px)' }),
                style({ transform: 'translateY(-5px)' }),
                style({ transform: 'translateY(5px)' }),
                style({ transform: 'translateY(0)' })
            ]));
        }

        const textPlayer = this._builder.build(this.transformAnimations[animationName]).create(
            this._el.nativeElement.children[0].children[0]
        );
        textPlayer.play();
    }
}
