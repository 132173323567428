import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class UrlService {
    private params: any;

    constructor(private activatedRoute: ActivatedRoute) {
        this.activatedRoute.queryParams.subscribe((params) => {
            this.params = params;
        });
    }

    public getValue(key: string): string {
        return this.params[key];
    }
}