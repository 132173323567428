<section class="paymentFirstScreen">
  <div class="container">

    <div class="sup_title upper center"><b i18n>FÁCIL Y SEGURO</b></div>
    <h1 class="title1 color-white center" i18n>
      Paga tu deuda <b>en minutos</b>
      <span class="accent-primary">.</span>
    </h1>

    <form class="searchForm" [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="searchForm__field">
        <input
          #searchInput
          type="search"
          formControlName="search"
          minlength="5"
          maxlength="63"
          [class.filled]="form.dirty">
        <span class="placeholder">Ingresa tu dominio, N° de orden o correo electrónico</span>
      </div>
      <button
        mat-button
        class="btn btnRose accent upper" [disabled]="(form.invalid || isLoading)" type="submit">
        CONSULTAR DEUDA
      </button>
    </form>

  </div>
</section>
