<section class="contacts_first_screen">
    <div class="container">
        <h1 class="ttl1">Formas de Pago</h1>
        <div class="sub_ttl">Elige una forma de pago para realizar tu compra</div>
    </div>
</section>

<section class="paymentsBlock__wrapper offset-top">
    <div class="container">
        <div class="countryNav">
            <a class="countryNav__item" [class.active]="activeCountry === country.country" (click)="selctedCountry(country.country); navigateToSection(country.country_code + '-ts')" *ngFor="let country of contactList">
                <span class="flag">
          <img src="{{country.flag_url}}" alt="flag">
        </span>
                <span class="name">{{country.country}}</span>
            </a>
        </div>

        <div class="paymentsBlock" [ngClass]="country.country_code + '-ts'" *ngFor="let country of contactList">
            <div class="paymentsBlock__country">
                <span class="flag">
          <img src="{{country.flag_url}}" alt="flag">
        </span>
                <span class="name">{{country.country}}</span>
            </div>
            <div class="paymentsBlock__inner">
                <div class="paymentsBlock__item" *ngFor="let payment of getDataPayments(country.country)">
                    <div class="info">
                        <div class="title" *ngIf="payment.method !== undefined">{{payment.method}}</div>
                        <div class="sub" *ngIf="payment.state !== undefined">{{payment.state}}</div>

                        <div class="inner" *ngIf="payment.bank_info !== undefined || payment.payment_info !== undefined" [preRender]="false" (deferLoad)="showImage1 = true">
                            <ng-container *ngIf="payment.payment_info !== undefined">
                                <ul class="bank-info v-2">
                                    <li>
                                        <span class="point">Titular:</span><span>{{payment.payment_info.point_1}}</span>
                                    </li>
                                    <li>
                                        <span class="point">№ cta. cte. soles S/.:</span><span>{{payment.payment_info.point_2}}</span>
                                    </li>
                                    <li *ngIf="payment.payment_info.point_3 !== undefined">
                                        <span class="point">N° de CCI S/.:</span><span>{{payment.payment_info.point_3}}</span>
                                    </li>
                                    <li>
                                        <span class="point">№ cta. cte. dólares USD:</span><span>{{payment.payment_info.point_4}}</span>
                                    </li>
                                    <li *ngIf="payment.payment_info.point_5 !== undefined">
                                        <span class="point">№ CCI dólares USD:</span><span>{{payment.payment_info.point_5}}</span>
                                    </li>
                                    <li>
                                        <span class="point">RUC:</span><span>{{payment.payment_info.point_6}}</span>
                                    </li>
                                </ul>
                                <div class="wrapImg">
                                    <ng-container *ngIf="showImage1">
                                        <img src="/assets/images/banks-logo/{{logo}}.png" alt="bank logo" *ngFor="let logo of payment.payment_info.logos">
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>

                    </div>
                    <div class="wrapImg" *ngIf="payment.logos !== undefined">
                        <ng-container *ngIf="showImage1">
                            <img src="/assets/images/banks-logo/{{logo}}.png" alt="bank logo" *ngFor="let logo of payment.logos">
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<ng-container *ngIf="showImage1">
    <app-footer></app-footer>
</ng-container>
