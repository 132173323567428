// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

export class URL {
  // DEVELOP.
  public static readonly HAULMER_WEBSITE = 'https://www.haulmer.com';
  public static readonly HAULMER_BLOG = 'https://www.haulmer.com/blog/';
  public static readonly HAULMER_NEWS = 'https://www.haulmer.com/news/';
  public static readonly OPENCLOUD_FACEBOOK = 'https://www.facebook.com/OpenCloudLAT/';
  public static readonly OPENCLOUD_TWITTER = 'https://twitter.com/OpenCloudLAT';
  public static readonly OPENCLOUD_LINKEDIN = 'https://www.linkedin.com/company/opencloud/about/';
  public static readonly OPENCLOUD_YOUTUBE = 'https://www.youtube.com/channel/UClZAro2djwGg-ka02GtecaQ';
  public static readonly OPENCLOUD_INSTAGRAM = 'https://www.instagram.com/opencloudlat/?hl=es-la';
  public static readonly OPENCLOUD_WEBSITE = 'https://www.opencloud.cl';
  public static readonly FIND_INVOICE = "https://whmcs-base.haulmer.dev/includes/api/findInvoices.php";
}
