import { Component, OnInit, Output, Inject } from '@angular/core';
import { Meta } from '../../../../common/Meta';
import { URL } from '../../environments/environment';
import { LocaleService } from '../services/locale.service';

@Component({
    selector: 'app-payment-forms-wrapper',
    templateUrl: './payment-forms-wrapper.component.html'
})
export class PaymentFormsWrapperComponent implements OnInit {
    @Output() Meta: Meta;
    @Output() TLD;

    public URL = URL;
    locale;

    constructor(
        private localeService: LocaleService,
    ) { }

    ngOnInit() {
        this.locale = this.localeService.locale;

        this.localeService.getMessage().subscribe(contact => {
            this.TLD = (contact.country_code === 'me' ? 'mx' : contact.country_code);
        });

        this.Meta = new Meta(
            'Formas de Pago | OpenCloud',
            'Conoce las formas de pago que OpenCloud deja a tu disposición. Cancela tus planes y disfrútalos ¡Ahora!',
            'OpenCloud',
            this.URL.OPENCLOUD_WEBSITE + '/assets/images/og/og-formas-de-pago.png',
            'png',
            644,
            374,
            'Formas de Pago | OpenCloud'
        );

        this.Meta.addKeyword('formas de pagos');
        this.Meta.addKeyword('métodos de pago');
    }
}
