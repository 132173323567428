import { Component, OnInit, HostBinding } from '@angular/core';
import { LocaleService } from '../services/locale.service';
import { MetaService } from '@ngx-meta/core';
import { URL } from '../../environments/environment';

@Component({
    selector: 'app-price-vps',
    templateUrl: './price-vps.component.html'
})

export class PriceVpsComponent implements OnInit {
    @HostBinding('class') classList = 'price';

    public URL = URL;
    locale;
    symbol = '';
    content;

    priceList;
    selectedRowOnTablet: string = 'o-ppt-contratar1';
    discountList;

    // lazy laoding images
    showImage1: boolean = false;
    showFooter: boolean = false;

    constructor(
        private localeService: LocaleService,
        private readonly _meta: MetaService
    ) {
        this.locale = this.localeService.locale;
        this.symbol = this.localeService.symbol;
        this.content = this.localeService.getContent().price;
        this.priceList = this.localeService.getContent().priceList;
        this.discountList = this.localeService.getContent().discount;
    }

    ngOnInit() {
        // Set Meta Tags
        if (this.locale === 'es-CO') {
            this._setMetaCO();
        } else if (this.locale === 'es-PE') {
            this._setMetaPE();
        } else if (this.locale === 'es-MX') {
            this._setMetaME();
        } else if (this.locale === 'es-AR') {
            this._setMetaAR();
        } else {
            this._setMetaCL();
        }
    }

    toggleOnTablet(id) {
        if (window.innerWidth <= 992) {
            this.selectedRowOnTablet = id;
        }
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    private _setMetaCL() {
        this._meta.setTitle('Precios | Increíble VPS a Precios Justos | OpenCloud Chile');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'VPS con el mejor precio del mercado en Chile. Mejor perfomance y rendimiento que VPS estándares con precio insuperable.');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'VPS, VPS SSD, VPS precios, servidor económico Chile, vps hosting precios, vps barato');
        this._meta.setTag('author', 'OpenCloud');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.OPENCLOUD_WEBSITE + '/assets/images/og/og-home-vps.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'VPS con el mejor precio del mercado en Chile');
        this._meta.setTag('og:locale', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_ME');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }

    private _setMetaPE() {
        this._meta.setTitle('Precios | Increíble SSD Cloud Server a Precios Justos | OpenCloud Perú');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'SSD Cloud Server con el mejor precio del mercado en Perú. Mejor perfomance y rendimiento que VPS estándares con precio insuperable.');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'ssd cloud server precios, servidor económico Perú, vps hosting precios, vps barato');
        this._meta.setTag('author', 'OpenCloud');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.OPENCLOUD_WEBSITE + '/assets/images/og/og-home-vps.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'SSD Cloud Server con el mejor precio del mercado en Perú');
        this._meta.setTag('og:locale', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_ME');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }

    private _setMetaCO() {
        this._meta.setTitle('Precios | Increíble SSD Cloud Server a Precios Justos | OpenCloud Colombia');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'SSD Cloud Server con el mejor precio del mercado en Colombia. Mejor perfomance y rendimiento que VPS estándares con precio insuperable.');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'ssd cloud server precios, servidor económico Colombia, vps hosting precios, vps barato');
        this._meta.setTag('author', 'OpenCloud');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.OPENCLOUD_WEBSITE + '/assets/images/og/og-home-vps.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'SSD Cloud Server con el mejor precio del mercado en Colombia');
        this._meta.setTag('og:locale', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_ME');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }

    private _setMetaME() {
        this._meta.setTitle('Precios | Increíble SSD Cloud Server a Precios Justos | OpenCloud México');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'SSD Cloud Server con el mejor precio del mercado en México. Mejor perfomance y rendimiento que VPS estándares con precio insuperable.');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'ssd cloud server precios, servidor económico México, vps hosting precios, vps barato');
        this._meta.setTag('author', 'OpenCloud');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.OPENCLOUD_WEBSITE + '/assets/images/og/og-home-vps.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'SSD Cloud Server con el mejor precio del mercado en Colombia');
        this._meta.setTag('og:locale', 'es_ME');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }

    private _setMetaAR() {
        this._meta.setTitle('Precios | Increíble SSD Cloud Server a Precios Justos | OpenCloud Argentina');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'SSD Cloud Server con el mejor precio del mercado en Argentina. Mejor perfomance y rendimiento que VPS estándares con precio insuperable.');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'ssd cloud server precios, servidor económico Argentina, vps hosting precios, vps barato');
        this._meta.setTag('author', 'OpenCloud');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.OPENCLOUD_WEBSITE + '/assets/images/og/og-home-vps.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'SSD Cloud Server con el mejor precio del mercado en Argentina');
        this._meta.setTag('og:locale', 'es_AR');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_ME');
    }
}
