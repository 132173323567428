<footer class="bg_grey">
  <div class="container">
    <div class="footer_top">
      <div class="wrap_logo">
        <a href="#">
          <img src="/assets/images/logos/opencloud-footer.svg" alt="Opencloud">
          <img src="/assets/images/logos/giphy_haulmer_footer_slogan.gif" alt="Made with love in chily">
        </a>
      </div>
      <div class="wrap_footer_menu">
        <h4 class="footer_menu__ttl">Sobre Opencloud</h4>
        <ul class="footer_menu">
          <li><a [routerLink]="['/ssd-cloud-server/precios/.']">Precios</a></li>
          <li><a [routerLink]="['/ssd-cloud-server/caracteristicas/.']">Características</a></li>
          <li><a [routerLink]="['/ssd-cloud-server/adicionales/.']">Adicionales</a></li>
          <li><a [routerLink]="['/formas-de-pago/.']">Formas de pago</a></li>
        </ul>
      </div>
      <div class="wrap_footer_menu">
        <h4 class="footer_menu__ttl">Haulmer</h4>
        <ul class="footer_menu">
          <li><a [href]="URL.HAULMER_WEBSITE" target="_blank" rel="noreferrer">Empresa</a></li>
          <li><a href="mailto:career@haulmer.com">Trabaja con nosotros</a></li>
          <li><a [href]="URL.HAULMER_BLOG" target="_blank">Blog</a></li>
          <li><a [href]="URL.HAULMER_NEWS" target="_blank">Noticias</a></li>
        </ul>
      </div>
      <div class="footer_contacts">
        <div class="soc">
          <a class="fb" [href]="URL.OPENCLOUD_FACEBOOK" target="_blank" rel="noreferrer">facebook</a>
          <a class="tw" [href]="URL.OPENCLOUD_TWITTER" target="_blank" rel="noreferrer">twitter</a>
          <a class="youtube" [href]="URL.OPENCLOUD_YOUTUBE" target="_blank" rel="noreferrer">youtube</a>
          <a class="linkedin" [href]="URL.OPENCLOUD_LINKEDIN" target="_blank" rel="noreferrer">linkedin</a>
          <a class="insta" [href]="URL.OPENCLOUD_INSTAGRAM" target="_blank" rel="noreferrer">instagram</a>
        </div>
        <p class="footer_contacts__ttl">¡Contáctanos!</p>
        <a href="tel:{{ contact.main_phone_href }}" class="tel">{{ contact.main_phone }}</a>
        <div class="box_country_list">
          <span class="flag">
            <img [src]="contact.flag_url" [alt]="contact.country">
          </span>
          <mat-form-field>
            <mat-select [(value)]="contact">
              <mat-option *ngFor="let contact of contactList" [value]="contact">
                <span class="flag"><img src="{{contact.flag_url}}" alt="Bandera país"></span>
                <span class="city">{{contact.city}}, </span>
                <span class="country">{{contact.country}}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="copiright">
      <img src="/assets/images/logos/img_copiright.svg" alt="Haulmer">
      <a href=" https://www.haulmer.com/legal/tag/ssd-cloud-server/" target="_blank" rel="noreferrer">Términos y condiciones</a>
      <span>Copyright Haulmer, Inc. © 2023. Todos los derechos reservados</span>
    </div>
  </div>

</footer>
