import { WebsiteCommonModule } from './../../../common/common.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { MetaModule, MetaService } from '@ngx-meta/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './home/home.component';
import { PriceComponent } from './price/price.component';
import { AdditionalComponent } from './additional/additional.component';
import { FeaturesComponent } from './features/features.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { DialogCustomMenuComponent } from './shared/dialogs/dialog-custom-menu/dialog-custom-menu.component';
import { BlockLogoComponent } from './shared/block-logo/block-logo.component';
import { ContactSupportComponent } from '../../../common/contact-support/contact-support.component';
import { ContactSupportWrapperComponent } from './contact-support-wrapper/contact-support-wrapper.component';
import { ContactsComponent } from '../../../common/contacts/contacts.component';
import { ContactWrapperComponent } from './contact/contact-wrapper.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { PaymentFormsWrapperComponent } from './payment-forms-wrapper/payment-forms-wrapper.component';
import { ChartSpeed1Component } from './shared/charts/chart-speed1/chart-speed1.component';
import { ChartSpeed2Component } from './shared/charts/chart-speed2/chart-speed2.component';
import { ChartSpeed3Component } from './shared/charts/chart-speed3/chart-speed3.component';
import { ChartSpeed4Component } from './shared/charts/chart-speed4/chart-speed4.component';
import { ChartSpeed5Component } from './shared/charts/chart-speed5/chart-speed5.component';
import { SuccessComponent} from './success/success.component';

import { CustomMaterialModule } from '../../../common/material.module';
import { LocaleService } from './services/locale.service';
import { CurrencyLocalePipe } from './currency.pipe';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { DeferLoadModule } from '@trademe/ng-defer-load';
import { SuccessGuard } from './success/success.guard';

import { NgxCaptchaModule } from 'ngx-captcha';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { HomeComponentVps } from './home-vps/home-vps.component';
import { PriceVpsComponent } from './price-vps/price-vps.component';
import { AdditionalVpsComponent } from './additional-vps/additional-vps.component';
import { FeaturesVpsComponent } from './features-vps/features-vps.component';

// pago-directo
import { DirectPaymentComponent } from './direct-payment/direct-payment.component';
import { DirectPaymentFirstScreenComponent } from './direct-payment/direct-payment-first-screen/direct-payment-first-screen.component';
import { SearchResultComponent } from './direct-payment/search-result/search-result.component';
import { NoSearchResultComponent } from './direct-payment/no-search-result/no-search-result.component';


@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        PriceComponent,
        AdditionalComponent,
        FeaturesComponent,
        HeaderComponent,
        FooterComponent,
        DialogCustomMenuComponent,
        BlockLogoComponent,
        ContactSupportComponent,
        ContactSupportWrapperComponent,
        ContactsComponent,
        ContactWrapperComponent,
        ErrorPageComponent,
        PaymentFormsWrapperComponent,
        CurrencyLocalePipe,
        ChartSpeed1Component,
        ChartSpeed2Component,
        ChartSpeed3Component,
        ChartSpeed4Component,
        ChartSpeed5Component,
        HomeComponentVps,
        PriceVpsComponent,
        AdditionalVpsComponent,
        FeaturesVpsComponent,
        SuccessComponent,
        // pago-directo
        DirectPaymentComponent,
        DirectPaymentFirstScreenComponent,
        SearchResultComponent,
        NoSearchResultComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'opencloud' }),
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        FormsModule,
        MetaModule.forRoot(),
        ReactiveFormsModule,
        CustomMaterialModule,
        IconSpriteModule,
        DeferLoadModule,
        NgxCaptchaModule,
        TransferHttpCacheModule,
        WebsiteCommonModule
    ],
    entryComponents: [
        DialogCustomMenuComponent
    ],
    providers: [
        MetaService,
        LocaleService,
        CurrencyLocalePipe,
        SuccessGuard
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
