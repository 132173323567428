<section class="contacts_first_screen support">
  <div class="container">
    <h1 class="ttl1">{{titlePage}}</h1>
    <div class="sub_ttl">¿Tienes alguna duda? Queremos ayudarte en lo que necesites</div>

    <div class="contactBlock">
      <a class="contactBlock__item" (click)="scrollTo('contacts')" *ngIf="Meta.author === 'Haulmer'">
        <span class="icon icon-phone"></span>
        <span>TELÉFONO</span>
      </a>
      <a class="contactBlock__item" href="tel:+56232109063" *ngIf="Meta.author !== 'Haulmer'">
        <span class="icon icon-phone"></span>
        <span>TELÉFONO</span>
      </a>
      <a class="contactBlock__item" (click)="openMessageBird()">
        <span class="icon icon-chat"></span>
        <span>CHAT</span>
      </a>
      <ng-template [ngIf]="showTicket">
        <a class="contactBlock__item" (click)="scrollTo('ticket')">
          <span class="icon icon-ticket"></span>
          <span>TICKET</span>
        </a>
      </ng-template>
      <ng-template [ngIf]="showWhatsapp">
        <a class="contactBlock__item">
          <span class="icon icon-whatsapp"></span>
          <span>Whatsapp</span>
        </a>
      </ng-template>
    </div>
  </div>
</section>

<section class="supportWrapper" #contacts>
  <div class="container">
    <h2 class="ttl2">Habla con nuestro equipo de <b>expertos</b></h2>
    <div class="sub_ttl2">Solucionarán tus dudas y problemas.</div>

    <ul class="listCounrty">
      <li *ngFor="let contact of contactList">
        <span>
          <span class="flag">
            <img src="{{contact.flag_url}}" alt="{{contact.country}}">
          </span>
          {{contact.city}}, <b>{{contact.country}}</b>
        </span>
        <a href="tel:{{contact.main_phone_href}}"><span class="phone">{{contact.main_phone}}</span></a>
      </li>
    </ul>
  </div>
</section>

<ng-template [ngIf]="showTicket">
  <section class="actionBlock" #ticket>
    <div class="wrapper">
      <div class="actionBlock__inner">
        <button class="btnBlueAction" mat-button (click)="goToUrl(linkSales)">Venta</button>
        <button class="btnBlueAction" mat-button (click)="goToUrl(linkSupport)">Soporte</button>
        <button class="btnBlueAction" mat-button (click)="goToUrl(linkPayment)">Pago</button>
      </div>
      <div class="actionBlock__inner">
        <h2 class="ttl2 align-right"><b>Enviar ticket</b> <br> de consulta</h2>
        <p class="align-right">Levanta un Ticket de Soporte para solicitar asistencia técnica y monitorear el estado de tu solicitud.</p>
      </div>
    </div>
  </section>
</ng-template>

<ng-content></ng-content>

<app-footer *ngIf="appType!=='CF'"></app-footer>
