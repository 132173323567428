import { Component, OnInit, ElementRef, HostBinding, ViewChild, Inject, PLATFORM_ID, AfterViewInit, HostListener } from '@angular/core';
import { ScrollToClass } from '../../../../common/scrollTo.class';
import { CONTACTS } from '../../../../common/contacts.constants';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { LocaleService } from '../services/locale.service';
import { MetaService } from '@ngx-meta/core';
import { URL } from '../../environments/environment';

@Component({
    selector: 'app-home-vps',
    templateUrl: './home-vps.component.html'
})

export class HomeComponentVps extends ScrollToClass implements OnInit, AfterViewInit {
    @HostBinding('class') classList = 'home';
    @ViewChild('serverVideo') public serverVideo: ElementRef;
    @ViewChild('videoHolder') public videoHolder: ElementRef;

    public URL = URL;
    contactList = CONTACTS;
    currentCountryCode;
    locale;
    symbol = '';

    selectedCard = 1;

    // video
    isShowVideo: boolean = false;
    controlsTimeout = null;

    // charts
    chartOpencloudEnd = 100;
    chartLinodeEnd = 60;
    chartAmazonEnd = 50;
    chartDigitalEnd = 45;
    chartProvidersEnd = 25;

    // lazy laoding images
    showImage1: boolean = false;
    showImage2: boolean = false;
    showImage3: boolean = false;
    showImage4: boolean = false;
    showFooter: boolean = false;

    content;

    currentDevice = 'desktop';

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.currentDevice = window.innerWidth < 480 ? 'mobile' : 'desktop';
    }

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private _el: ElementRef,
        @Inject(DOCUMENT) private _document: Document,
        private localeService: LocaleService,
        private readonly _meta: MetaService
    ) {
        super(_el);
        this.locale = this.localeService.locale;
        this.symbol = this.localeService.symbol;
        this.content = this.localeService.getContent().home;
        this.currentCountryCode = this.localeService.currentCountryCode;
    }

    ngOnInit() {
        // Set Meta Tags
        if (this.locale === 'es-CO') {
            this._setMetaCO();
        } else if (this.locale === 'es-PE') {
            this._setMetaPE();
        } else if (this.locale === 'es-MX') {
            this._setMetaME();
        } else if (this.locale === 'es-AR') {
            this._setMetaAR();
        } else {
            this._setMetaCL();
        }

        this.currentDevice = window.innerWidth < 480 ? 'mobile' : 'desktop';
    }

    ngAfterViewInit() {
        if (isPlatformBrowser(this.platformId)) {
            this.initSlider();
        }
    }

    getCurrentCountry() {
        const country = this.contactList.filter((c) => c.country_code === this.currentCountryCode);
        return country[0];
    }

    navigateToSection(className) {
        setTimeout(() => {
            this.scrollToSection(className);
        }, 200);
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    initSlider() {
        const slider = tns({
            container: '.sliderReviews-ts',
            items: 1,
            speed: 400,
            loop: true,
            nav: true,
            autoHeight: true,
            controls: false,
        });
    }

    openCard(id) {
        this.selectedCard = id;
    }

    /**
    *** SATRT video playback
    **/
    playVideo() {
        this.isShowVideo = true;

        setTimeout(() => {
            this.createVideoElements();

            const video = this.serverVideo.nativeElement;
            const playEl = <HTMLElement>this.videoHolder.nativeElement.getElementsByClassName('play')[0];
            const body = <HTMLElement>this._document.getElementsByTagName('body')[0];

            body.style.overflow = 'hidden';
            body.classList.add('open-video');
            video.play();

            playEl.addEventListener('click', () => {
                if (playEl.classList.contains('pause')) {
                    playEl.classList.remove('pause');
                    video.pause();
                } else {
                    playEl.classList.add('pause');
                    video.play();
                    video.volume = 1.0;
                }
            });

            this.movePlaceholder();

            setTimeout(() => {
                this.moveProgress();
                this.volumeControll();
                this.toggleMuted();
            }, 100);

        }, 100);
    }

    createVideoElements() {
        this.videoHolder.nativeElement.insertAdjacentHTML('beforeend',
            '<div class=\"video-controls\">\
                    <div class=\"play pause\"><span></span><span></span></div>\
                    <div class=\"volume\">\
						<div class=\"icon\"></div>\
						<div class=\"bar\"><span class=\"bar-fill\"></span></div>\
					</div>\
					<div class=\"progress\">\
						<div class=\"progress-inner\">\
							<div class=\"fill\"></div>\
							<div class=\"placeholder\"></div>\
						</div>\
					</div>\
                </div>');
    }

    movePlaceholder() {
        const progress = <HTMLElement>this.videoHolder.nativeElement.getElementsByClassName('progress')[0];
        const paceholder = <HTMLElement>this.videoHolder.nativeElement.getElementsByClassName('placeholder')[0];

        progress.addEventListener('mousemove', (ev) => {
            paceholder.style.width = ev.offsetX + 'px';
        });
    }

    moveProgress() {
        const video = this.serverVideo.nativeElement;
        const controls = <HTMLElement>this.videoHolder.nativeElement.getElementsByClassName('video-controls')[0];
        const playEl = <HTMLElement>this.videoHolder.nativeElement.getElementsByClassName('play')[0];
        const progress = <HTMLElement>this.videoHolder.nativeElement.getElementsByClassName('progress')[0];
        const fill = <HTMLElement>this.videoHolder.nativeElement.getElementsByClassName('fill')[0];
        const progressRect = progress.getBoundingClientRect();

        // update progress line as time changes
        video.addEventListener('timeupdate', () => {
            fill.style.width = (progressRect.width * video.currentTime) / video.duration + 'px';

            if (fill.style.width === progressRect.width + 'px') {
                playEl.classList.remove('pause');
                video.pause();
            }
        });

        // hide the Play button when stoping moving the cursor
        const hideControls = (ev) => {
            if (ev.type == 'touchstart' && controls.classList.contains('hide')) {
                ev.preventDefault();
            }

            controls.classList.remove('hide');

            if (this.controlsTimeout !== null) {
                clearTimeout(this.controlsTimeout);
                this.controlsTimeout = null;
            }

            if (playEl.classList.contains('pause')) {
                this.controlsTimeout = setTimeout(() => {
                    controls.classList.add('hide');
                }, 2000);
            }
        };

        playEl.addEventListener('mousemove', hideControls);
        playEl.addEventListener('touchstart', hideControls);

        // update line progress when clicking progress
        progress.addEventListener('click', (ev) => {
            video.currentTime = (ev.offsetX * video.duration) / progressRect.width;
            fill.style.width = ev.offsetX + 'px';
        });
    }

    volumeControll() {
        const video = this.serverVideo.nativeElement;
        const volumeBar = <HTMLElement>this.videoHolder.nativeElement.getElementsByClassName('bar')[0];
        const volumeFill = <HTMLElement>this.videoHolder.nativeElement.getElementsByClassName('bar-fill')[0];
        const volumeBarRect = volumeBar.getBoundingClientRect();

        // update volume when clicking bar
        volumeBar.addEventListener('click', (ev) => {
            video.volume = (100 - ev.offsetY) / volumeBarRect.height;
            volumeFill.style.height = (100 - ev.offsetY) + 'px';
        });
    }

    toggleMuted() {
        const video = this.serverVideo.nativeElement;
        const iconVolume = <HTMLElement>this.videoHolder.nativeElement.getElementsByClassName('icon')[0];

        iconVolume.addEventListener('click', (ev) => {
            if (iconVolume.classList.contains('muted')) {
                iconVolume.classList.remove('muted');
                video.removeAttribute('muted');
            } else {
                iconVolume.classList.add('muted');
                video.setAttribute('muted', '');
            }
        });
    }

    hideVideo() {
        const video = this.serverVideo.nativeElement;
        const customControls = <HTMLElement>this.videoHolder.nativeElement.getElementsByClassName('video-controls')[0];
        const body = <HTMLElement>this._document.getElementsByTagName('body')[0];
        body.classList.remove('open-video');
        video.currentTime = 0;
        this.isShowVideo = false;
        this.serverVideo.nativeElement.parentNode.removeChild(customControls);
        body.style.overflow = null;
    }
    //  END video playback

    private _setMetaCL() {
        this._meta.setTitle('VPS SSD en Linux y Windows | OpenCloud Chile');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'OpenCloud es VPS en Chile con el servicio de más alto rendimiento en el mercado al precio justo. SSD Cloud Server con un perfomance hasta 2 veces mayor.');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'vps, vps ssd, vps hosting, ssd cloud server, cloud server, vps Chile, vps chile, vps barato');
        this._meta.setTag('author', 'OpenCloud');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.OPENCLOUD_WEBSITE + '/assets/images/og/og-home-vps.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'VPS para Developers con Mejor Latencia a $2.500 en Chile');
        this._meta.setTag('og:locale', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_ME');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }

    private _setMetaPE() {
        this._meta.setTitle('SSD Cloud Server en Linux y Windows | OpenCloud Perú');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'OpenCloud es SSD Cloud Server en Perú con el servicio de más alto rendimiento en el mercado al precio justo. SSD Cloud Server con un perfomance hasta 2 veces mayor.');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'ssd cloud server, cloud server, vps hosting, vps perú, cloud server peru, ssd cloud server barato');
        this._meta.setTag('author', 'OpenCloud');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.OPENCLOUD_WEBSITE + '/assets/images/og/og-home-vps.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'SSD Cloud Server para Developers con Mejor Latencia a S/15 en Perú');
        this._meta.setTag('og:locale', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_ME');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }

    private _setMetaCO() {
        this._meta.setTitle('SSD Cloud Server en Linux y Windows | OpenCloud Colombia');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'OpenCloud es SSD Cloud Server en Colombia con el servicio de más alto rendimiento en el mercado al precio justo. SSD Cloud Server con un perfomance hasta 2 veces mayor.');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'ssd cloud server, cloud server, vps hosting, vps Colombia, cloud server Colombia, ssd cloud server barato');
        this._meta.setTag('author', 'OpenCloud');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.OPENCLOUD_WEBSITE + '/assets/images/og/og-home-vps.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'SSD Cloud Server para Developers con Mejor Latencia a $15.000 en Colombia');
        this._meta.setTag('og:locale', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_ME');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }

    private _setMetaME() {
        this._meta.setTitle('SSD Cloud Server en Linux y Windows | OpenCloud México');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'OpenCloud es SSD Cloud Server en México con el servicio de más alto rendimiento en el mercado al precio justo. SSD Cloud Server con un perfomance hasta 2 veces mayor.');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'ssd cloud server, cloud server, vps hosting, vps México, cloud server México, ssd cloud server barato');
        this._meta.setTag('author', 'OpenCloud');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.OPENCLOUD_WEBSITE + '/assets/images/og/og-home-vps.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'SSD Cloud Server para Developers con Mejor Latencia a $90 en México');
        this._meta.setTag('og:locale', 'es_ME');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }

    private _setMetaAR() {
        this._meta.setTitle('SSD Cloud Server en Linux y Windows | OpenCloud Argentina');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'OpenCloud es SSD Cloud Server en Argentina con el servicio de más alto rendimiento en el mercado al precio justo. SSD Cloud Server con un perfomance hasta 2 veces mayor.');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'ssd cloud server, cloud server, vps hosting, vps Argentina, cloud server Argentina, ssd cloud server barato');
        this._meta.setTag('author', 'OpenCloud');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.OPENCLOUD_WEBSITE + '/assets/images/og/og-home-vps.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'SSD Cloud Server para Developers con Mejor Latencia a $75 en Argentina');
        this._meta.setTag('og:locale', 'es_AR');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_ME');
    }
}
