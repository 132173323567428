<div class="sectionNoResult">
  <div class="sectionNoResult__info">
    <div class="sectionNoResult__info-title">
      <svg-icon-sprite
        [src]="'/assets/images/sprites/sprite.svg#' + data.icon"
        [width]="'33px'"
        [ngClass]="!!data.state ? data.state : ''">
      </svg-icon-sprite>
      <span i18n>{{data.title}}</span>
    </div>
    <div class="sectionNoResult__info-text">
      <span i18n>{{data.text}}</span>
      <a [routerLink]="['/contactar-con-soporte/.']" *ngIf="data.type === 'noResult'" i18n>Contactar con soporte</a>
    </div>
  </div>
</div>
