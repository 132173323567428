<header #header class="wrapHeader" [ngClass]="getClassHeader()">
  <div class="container">
    <div class="wrapHeader__top">
      <a class="logo" href="/">
        <img class="logo-white" src="/assets/images/logos/opencloud-white.svg" alt="Opencloud">
        <img class="logo-dark" src="/assets/images/logos/opencloud-dark.svg" alt="Opencloud">
        <span>By Haulmer</span>
      </a>

      <div class="wrapBtn">
        <button [id]="isMobile ? 'oc-contactsales' : ''" class="btnWhite" mat-button (click)="goToUrl('contactar-con-ventas/.')">Contactar con ventas</button>
        <button [id]="isMobile ? 'o-m-ingresar1' : ''" class="btnTransp" mat-button (click)="goToUrl('https://panel.opencloud.host/clientarea.php')">Ingresar</button>
      </div>

      <button class="humburger" mat-icon-button (click)="onClickOpenMenu();scrollTop()">
        <span class="humburger_inner"></span>
      </button>
    </div>

    <!-- start nav desktop -->
    <nav class="wrapHeader__nav">
      <ul class="wrapHeader__menu">
        <li>
          <a [id]="isMobile ? 'o-mp-precios1' : ''"
            [routerLinkActive]="['active']"
            [routerLink]="['ssd-cloud-server/precios/.']"
            (click)="onClickCloseMenu()">
            Precios
          </a>
        </li>
        <li>
          <a [id]="isMobile ? 'o-mp-caracteristicas1' : ''"
            [routerLinkActive]="['active']"
            [routerLink]="['ssd-cloud-server/caracteristicas/.']"
            (click)="onClickCloseMenu()">
            Características
          </a>
        </li>
        <li>
          <a [id]="isMobile ? 'o-mp-adicionales1' : ''"
            [routerLinkActive]="['active']"
            [routerLink]="['ssd-cloud-server/adicionales/.']"
            (click)="onClickCloseMenu()">
            Adicionales
          </a>
        </li>
        <li>
          <a [id]="isMobile ? 'o-mp-header-pago' : ''" [routerLink]="['/pago-directo/.']">
            <svg-icon-sprite
              [src]="'/assets/images/sprites/sprite.svg#icon-sprite-credit-card'"
              [width]="'16px'">
            </svg-icon-sprite>
            <span>Pago de cuentas</span>
          </a>
        </li>
        <li>
          <a [id]="isMobile ? 'oc-support-doc' : ''" #triggerDialog (click)="openDialogCustomMenu()">
            Soporte <mat-icon>keyboard_arrow_down</mat-icon>
          </a>
        </li>
      </ul>
    </nav>
    <!-- end nav desktop -->

  </div>
</header>

<!-- start nav mobyle -->
<nav class="mobyleMenu">
  <a [routerLink]="['/']" class="logo" (click)="onClickCloseMenu();scrollTop()" *ngIf="!isActiveDropdownSupport">
    <img src="/assets/images/logos/opencloud-dark.svg" alt="Opencloud">
  </a>

  <button mat-icon-button class="close" (click)="onClickCloseMenu()">
    <mat-icon>close</mat-icon>
  </button>

  <div class="mobyleMenu__ttl" *ngIf="isActiveDropdownSupport">
    <button mat-icon-button
      (click)="closeDropdownMenu('soporte')">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    Soporte
  </div>

  <div class="wrapBtn">
    <button [id]="isMobile ? '' : 'oc-contactsales'" class="btnBlue" mat-button (click)="onClickCloseMenu();goToUrl('contactar-con-ventas/.')">
      Contactar con ventas
    </button>
  </div>

  <div class="wrapBtn">
    <button [id]="isMobile ? '' : 'o-m-ingresar1'" class="btnTransp" mat-button (click)="goToUrl('https://panel.opencloud.host/clientarea.php')">Ingresar</button>
  </div>


  <ul class="mobyleMenu__menu" *ngIf="!isActiveDropdownSupport">
    <li>
      <a [id]="isMobile ? '' : 'o-mp-precios1'"
        [routerLinkActive]="['active']"
        [routerLink]="['ssd-cloud-server/precios/.']"
        (click)="onClickCloseMenu()">
        Precios
      </a>
    </li>
    <li>
      <a [id]="isMobile ? '' : 'o-mp-caracteristicas1'"
        [routerLinkActive]="['active']"
        [routerLink]="['ssd-cloud-server/caracteristicas/.']"
        (click)="onClickCloseMenu()">
        Características
      </a>
    </li>
    <li>
      <a [id]="isMobile ? '' : 'o-mp-adicionales1'"
        [routerLinkActive]="['active']"
        [routerLink]="['ssd-cloud-server/adicionales/.']"
        (click)="onClickCloseMenu()">
        Adicionales
      </a>
    </li>
    <li>
      <a
        [id]="isMobile ? '' : 'o-mp-header-pago'"
        [routerLink]="['/pago-directo/.']"
        [routerLinkActive]="['active']"
        (click)="onClickCloseMenu()">
        <svg-icon-sprite
          [src]="'/assets/images/sprites/sprite.svg#icon-sprite-credit-card'"
          [width]="'16px'">
        </svg-icon-sprite>
        <span>Pago de cuentas</span>
      </a>
    </li>
    <li>
      <a [id]="isMobile ? '' : 'oc-support-doc'" (click)="openDropdownMenu('soporte')">
        <span>Soporte</span>
        <mat-icon>keyboard_arrow_down</mat-icon>
      </a>
    </li>
  </ul>

  <div class="dropdownWrapper" *ngIf="isActiveDropdownSupport">
    <ul class="customDropdownMenu">
      <li class="customDropdownMenu__item">
        <a id="oc-support" (click)="goToUrl('contactar-con-soporte')">
          <i class="icon icon_support"></i>
          <span class="title">Contactar Soporte</span>
        </a>
      </li>
      <li class="customDropdownMenu__item">
        <a id="oc-support-doc" href="https://help.haulmer.com/hc/cloud-server" target="_blank">
          <i class="icon icon_doc"></i>
          <span class="title">Documentación</span>
        </a>
      </li>
      <li class="customDropdownMenu__item">
        <a id="oc-support-suggestions" href="https://survey.typeform.com/to/zQ7I32J9#empresa=Opencloud&issuetype=11207" target="_blank">
          <i class="icon icon_suggestions"></i>
          <span class="title">Sugerencias o reclamos</span>
        </a>
      </li>
      <!-- <li class="customDropdownMenu__item">
        <a>
          <i class="icon icon_status_service"></i>
          <span class="title">Status de Servicios</span>
        </a>
      </li> -->
    </ul>
  </div>
</nav>
<!-- end nav mobyle -->

<div class="overlay" (click)="onClickCloseMenu()"></div>
