<section class="firstScreen">
  <ng-container *ngIf="isShowVideo">
    <div #videoHolder class="video_holder" [class.show-video]="isShowVideo">
      <button class="btnClose" mat-icon-button (click)="hideVideo()"><mat-icon>close</mat-icon></button>
      <video #serverVideo src="/assets/video/server-video.mp4" preload="metadata" playsinline></video>
    </div>
  </ng-container>


  <div class="container">

    <div class="firstScreen__info">
      <h1 class="title1 color-white" >
        <b>VPS SSD</b> para <b>Developers</b> con Mejor Latencia desde <b class="price"><span class="currensy">{{symbol}}</span>2.500</b> en <span class="country"><b>{{getCurrentCountry()['country']}}</b></span>
      </h1>

      <button id="o-b-contratar1" class="btn btnRose upper" mat-button (click)="navigateToSection('aboutPlans__wrapper')" >
        CONTRATAR
      </button>
    </div>

    <button id="o-b-video" class="btnPlay" mat-icon-button (click)="playVideo()">
      <span class="text" >Clic para ver</span>
    </button>

  </div>
</section>

<!-- START aboutPlans -->
<section class="aboutPlans__wrapper">
  <div class="container">
    <div class="sup_title upper center" >Planes VPS SSD</div>
    <h2 class="title2 center" ><b>Precios simples</b> y costos fijos</h2>

    <div class="aboutPlans">
      <div class="aboutPlans__container">
        <div class="aboutPlans__card" [class.open]="selectedCard === card.id" *ngFor="let card of content.planList">
          <div class="aboutPlans__card-nav" (click)="openCard(card.id)">
            <div class="inner">
              <span *ngIf="card.label" class="label" >Popular</span>
              <div class="price"><span class="currency">{{symbol}}</span>{{card.price}}</div>
              <div class="sub_price" >Mensual + IVA</div>
            </div>
            <button mat-icon-button><mat-icon>keyboard_arrow_down</mat-icon></button>
          </div>
          <div class="aboutPlans__card-body">
            <div class="info">
              <span >Memoria</span><b>{{card.memory}}</b>
            </div>
            <div class="info">
              <span >vCPUs</span><b>{{card.vcpu}}</b>
            </div>
            <div class="info">
              <span >SSD Disco</span><b>{{card.ssd}}</b>
            </div>
            <div class="info">
              <span >Tráfico</span><b>{{card.traffic}}</b>
            </div>
            <button [id]=card.btn_id class="btn btnTranspBlue upper" mat-button (click)="goToUrl(card.btn_link)" >
              {{card.btn_text}}
            </button>
          </div>
        </div>
      </div>

      <button id="o-hp-verplanes1"
              class="btn btnPrimary upper"
              mat-button
              [routerLink]="['ssd-cloud-server/precios']"
              [preRender]="false" (deferLoad)="showImage1 = true" >
        Ver todos los planes
      </button>
      <h3 class="title3" >Todos los planes incluyen</h3>

      <ul class="aboutPlans__list">
        <li *ngFor="let point of content.listOfPlans">
          <span [innerHtml]="point.text" ></span>
            <ng-container *ngIf="showImage1"><!-- showImage1 because we need render before. -->
              <span class="icon accent">
                <svg-icon-sprite
                  [src]="'/assets/images/sprites/sprite.svg#' + point.icon"
                  [width]="'28px'"
                  [classes]="point.icon">
                </svg-icon-sprite>
              </span>
            </ng-container>
        </li>
      </ul>

    </div>
  </div>
</section>
<!-- END aboutPlans -->

<!-- START aboutProduct -->
<section class="aboutProduct v1" [preRender]="false" (deferLoad)="showImage2 = true">
  <div class="container">
    <div class="wrapper reverse">
      <div class="aboutProduct__info">
        <div class="sup_title upper" >Velocidad</div>
        <h2 class="title2" ><b>Velocidad y alto rendimiento</b> para tu VPS</h2>
        <ul class="checkList">
          <li  *ngFor="let point of content.checkList_1">
            <ng-container *ngIf="showImage1">
              <span class="icon accent">
                <svg-icon-sprite
                  class="stroke"
                  [src]="'/assets/images/sprites/sprite.svg#icon-sprite-check'"
                  [width]="'16px'"
                  [classes]="icon-sprite-check">
                </svg-icon-sprite>
              </span>
            </ng-container>
            {{point}}
          </li>
        </ul>
      </div>

      <div class="aboutProduct__img charts-ts">
        <ng-container *ngIf="showImage1"><!-- showImage1 because we need render before. -->
          <div class="chartsContainer">
            <app-chart-speed1 [endValue]="chartOpencloudEnd"></app-chart-speed1>
            <app-chart-speed2 [endValue]="chartLinodeEnd"></app-chart-speed2>
            <app-chart-speed3 [endValue]="chartAmazonEnd"></app-chart-speed3>
            <app-chart-speed4 [endValue]="chartDigitalEnd"></app-chart-speed4>
            <app-chart-speed5 [endValue]="chartProvidersEnd"></app-chart-speed5>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</section>
<!-- END aboutProduct -->

<!-- START -->
<section class="sectionInfo bg_light v1" [preRender]="false" (deferLoad)="showFooter = true">
  <div class="container">
    <div class="sup_title upper center" >Estabilidad</div>
    <h2 class="title2 center" ><b>Estabilidad y continuidad operacional</b> para tus proyectos</h2>

    <div class="blockAdvantages">
      <div class="blockAdvantages__item" *ngFor="let item of content.advantagesList">
        <span class="title" >{{item.title}}</span>
        <span class="count">{{item.count}}</span>
        <span class="sub" >{{item.sub}} <br> <b class="upper">{{item.sub2}}</b></span>
      </div>
    </div>
  </div>
</section>
<!-- END -->

<!-- START aboutProduct -->
<section class="aboutProduct v2" [preRender]="false" (deferLoad)="showImage3 = true">
  <div class="container">
    <div class="wrapper">
      <div class="aboutProduct__info">
        <div class="sup_title upper" >Velocidad</div>
        <h2 class="title2" ><b>Control absoluto</b> de tu VPS</h2>
        <p >Un VPS completamente tuyo. Administra libremente todas las características de tu servidor. Las posibilidades son infinitas.</p>
        <ul class="checkList">
          <li  *ngFor="let point of content.checkList_2">
            <ng-container *ngIf="showImage2"><!-- showImage2 because we need render before. -->
              <span class="icon accent">
                <svg-icon-sprite
                  class="stroke"
                  [src]="'/assets/images/sprites/sprite.svg#icon-sprite-check'"
                  [width]="'16px'"
                  [classes]="icon-sprite-check">
                </svg-icon-sprite>
              </span>
            </ng-container>
            {{point}}
          </li>
        </ul>
      </div>

      <div class="aboutProduct__img height-560">
        <ng-container *ngIf="showImage2"><!-- showImage2 because we need render before. -->
          <img  src="/assets/images/home/control-server-desktop@1x.png"
                srcset="/assets/images/home/control-server-desktop@2x.png 2x" alt="control cloud server">
        </ng-container>
      </div>
    </div>
  </div>
</section>
<!-- END aboutProduct -->

<!-- START sliderReviews -->
<div class="sectionReviews__wrapper" [preRender]="false" (deferLoad)="showImage4 = true">
  <div class="sliderReviews-ts">
    <div class="sectionReviews" [class.show-image]="showImage3"
      [ngStyle]="{'background-image': 'url(/assets/images/home/' + item.img + '-' + currentDevice + '.jpg)'}"
      *ngFor="let item of content.reviews">
      <div class="container">
        <div class="sectionReviews__inner">
          <div class="sup_title upper color-white" >TESTIMONIOS</div>
          <h2 class="title2 color-white" ><b>Ellos confían</b> <br> en OPENCLOUD</h2>
          <div>
            <div id="{{item.id}}" class="sectionReviews__slide">
              <div class="text" >{{item.text}}</div>
              <div class="name" >{{item.name}}</div>
              <div class="prof" >{{item.prof}} <a href="{{item.url}}" target="_blank">{{item.link}}</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END sliderReviews -->

<section class="logoBanks__wrapper center">
  <div class="container">
    <h2 class="title2 center" >Formas de <b>pago</b></h2>
    <p class="center" >En OpenCloud queremos facilitar los trámites e ir directo a los beneficios, por esta razón te ofrecemos distintas formas de pago. Contrata tu VPS cancelando con tarjetas de crédito, débito, dinero electrónico y transferencias bancarias.
    </p>

  </div>
  <div class="inner">
    <ng-container *ngIf="showImage4"><!-- showImage4 because we need render before. -->
      <picture>
        <source srcset="{{ content.payment.img_desktop_x1 }},
                        {{ content.payment.img_desktop_x2 }}" media="(min-width: 1200px)">
        <source srcset="{{ content.payment.img_tablet_x1 }},
                        {{ content.payment.img_tablet_x2 }} 2x" media="(min-width: 480px)">
        <img  src="{{ content.payment.img_mobile_x1 }}"
              srcset="{{ content.payment.img_mobile_x2 }} 2x" alt="logos banks">
      </picture>
    </ng-container>

  </div>
</section>

<ng-container *ngIf="showFooter"><!-- showFooter because we need render before. -->
  <app-block-logo></app-block-logo>

  <app-footer></app-footer>
</ng-container>

