import { Contact } from './../../../../common/Contact';
import { CONTACTS } from './../../../../common/contacts.constants';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Inject, Output } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { Meta } from '../../../../common/Meta';
import { URL } from '../../environments/environment';

@Component({
    selector: 'app-contact-wrapper',
    templateUrl: './contact-wrapper.component.html',

})
export class ContactWrapperComponent implements OnInit {
    public URL = URL;
    playVideo = false;

    @Output() TLD = 'cl';
    @Output() Meta: Meta;
    @Output() MessageFrom = 'Opencloud';
    @Output() ContactList: Contact[] = CONTACTS.filter(c => c.country_code === 'cl');

    constructor(
        @Inject(DOCUMENT) private _document: Document,
        private route: ActivatedRoute
    ) {
        this.Meta = new Meta(
            'Contactar con ventas | Opencloud',
            // tslint:disable-next-line:max-line-length
            'Completa tus datos y en pocos segundos uno de nuestros ejecutivos de venta se comunicará contigo. Descubre todo lo que Opencloud tiene para ofrecer.',
            'Opencloud',
            this.URL.OPENCLOUD_WEBSITE + '/assets/images/og-contact.png',
            'png',
            644,
            374,
            'Contacto con ventas, Opencloud'
        );

        this.Meta.addKeyword('contactar ventas');
        this.Meta.addKeyword('ventas');
        this.Meta.addKeyword('atención al cliente');
        this.Meta.addKeyword('centro de soporte');
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            if (params.partner === 'true') {
                this.MessageFrom = 'Opencloud - Quiero ser socio';
            }
        });
    }
}
