<section class="tablePrice__wrapper">
  <div class="container">

    <div class="inner">
      <div class="sup_title center upper" >Planes VPS</div>
      <h2 class="title2 center" ><b>Precios simples</b> y costos fijos</h2>

      <div class="tablePrice__inner" *ngFor="let plan of priceList">
        <h3 class="title3" >{{plan.plan}}</h3>
        <p >{{plan.text}}</p>

        <!-- START tablePrice -->
        <div class="tablePrice">
          <div class="tablePrice__header">
            <div class="tablePrice__header-td" *ngFor="let headerCell of plan.table_header" >{{headerCell}}</div>
          </div>

          <div class="tablePrice__body">
            <div class="tablePrice__body-row" [class.open]="selectedRowOnTablet === rowCell.btnId" *ngFor="let rowCell of plan.table" (click)="toggleOnTablet(rowCell.btnId)">
              <span class="label" *ngIf="rowCell.isLabel" >Popular</span>
              <div class="tablePrice__body-td">{{rowCell.memory}}</div>
              <div class="tablePrice__body-td">
                <span class="title" >VCPUS</span>
                <span >{{rowCell.vcpu}}</span>
              </div>
              <div class="tablePrice__body-td">
                <span class="title" >SSD DISCO</span>
                <span>{{rowCell.ssd}}</span>
              </div>
              <div class="tablePrice__body-td">
                <span class="title" >TRÁFICO</span>
                <span>{{rowCell.trafic}}</span>
              </div>
              <div class="tablePrice__body-td column">
                <div class="inner">
                  <span><span>{{symbol}}</span> {{rowCell.price}}</span>
                  <span class="sub" >mensual</span>
                </div>
                <button mat-icon-button><mat-icon>keyboard_arrow_down</mat-icon></button>
              </div>
              <div class="tablePrice__body-td">
                <button id="{{rowCell.btnId}}" class="btn" mat-button (click)="goToUrl(rowCell.btnLink)" >Contratar</button>
              </div>
            </div>
          </div>
        </div>
        <div class="sub_text" >* Los precios no incluyen IVA</div>
        <!-- END tablePrice -->
      </div>
    </div>

    <!-- START tableDiscount -->
    <div class="tableDiscount" [preRender]="false" (deferLoad)="showImage1 = true">
      <div class="tableDiscount__header" ><b>Descuento</b> según periodo de contratación</div>
      <div class="tableDiscount__body">
        <div class="tableDiscount__body-cell" *ngFor="let item of discountList">
          <span>{{item.discount}}</span>
          <span >{{item.sub}}</span>
        </div>
      </div>
    </div>
    <!-- END tableDiscount -->
  </div>
</section>

<!-- START blockInfo -->
<section class="blockInfo bg_rose v1">
  <div class="container">
    <h2 class="title2" >Todos nuestros planes incluyen</h2>
    <ul class="blockInfo__list">
      <li *ngFor="let point of content.infoList">
        <span [innerHtml]="point.text" ></span>
        <ng-container *ngIf="showImage1"><!-- showImage1 because we need render before. -->
          <span class="icon">
            <svg-icon-sprite
              [src]="'/assets/images/sprites/sprite.svg#' + point.icon"
              [width]="'28px'"
              [classes]="point.icon">
            </svg-icon-sprite>
          </span>
        </ng-container>
      </li>
    </ul>
  </div>
</section>
<!-- END blockInfo -->

<section class="textColumn__wrapper" [preRender]="false" (deferLoad)="showFooter = true">
  <div class="container">
    <h2 class="title2 center" ><b>Preguntas Frecuentes</b></h2>

    <div class="textColumn">
      <div class="textColumn__column">
        <h3 class="title3" >¿Cuentan con enlaces dedicados?</h3>
        <p >Sí, tenemos 3 enlaces dedicados, incluyendo 2 internacionales de Tier 1 con las empresas Internexa y CenturyLink.</p>
        <h3 class="title3" >¿Puedo pedir reembolso?</h3>
        <p >Recuerda que nuestro equipo de soporte está disponible para ayudarte en todo momento. Sin embargo, si aún quieres solicitar un reembolso del servicio contratado lo puedes hacer dentro de los primeros 7 días. Los siguientes servicios no cuentan con reembolso: licencias, soporte pagado y enlaces dedicados.</p>
        <h3 class="title3" >¿Cuál es la velocidad de enlace de internet de mi VPS?</h3>
        <p >Las siguientes son las velocidades de acuerdo a los planes:</p>
        <ul>
          <li >Cloud Server 1 = 60 mbit</li>
          <li >Cloud Server 2 = 60 mbit</li>
          <li >Cloud Server 3 = 70 mbit</li>
          <li >Cloud Server 4 = 70 mbit</li>
          <li >Cloud Server 5 = 80 mbit</li>
          <li >Cloud Server 6 = 80 mbit</li>
          <li >Cloud Server 7 = 90 mbit</li>
          <li >Cloud Server 8 = 90 mbit</li>
          <li >Cloud Server 9 = 100 mbit</li>
        </ul>
        <p >Sin embargo, la velocidad de enlace es entregada como mejor esfuerzo y no es garantizada.</p>
      </div>
      <div class="textColumn__column">
        <h3 class="title3" >¿Por qué tenemos los mejores precios del mercado?</h3>
        <p >La política de OpenCloud siempre ha apuntado a democratizar la tecnología, entregando servicios de alta calidad a los precios más bajos posibles para que más personas puedan desarrollar sus ideas.</p>
        <h3 class="title3" >¿Dónde se encuentran sus servidores?</h3>
        <p >Nuestros Data Centers están ubicados en Chile y cuentan con conexiones directas a los demás países de Latinoamérica.</p>
        <h3 class="title3" >¿Cuándo eliminarán mi información luego de la suspensión?</h3>
        <p >Al cumplirse el periodo contratado el servicio será suspendido. 10 días después de la suspensión, el servicio será terminado y toda su información alojada será eliminada sin la posibilidad de recuperación.</p>
        <h3 class="title3" >¿Qué tipo de soporte está incluido en mi plan?</h3>
        <p >Los servicios VPS son en modalidad no-administrado, esto quiere decir que como cliente eres 100% responsable de administrar tu VPS. El soporte incluido solo verificará que tu servidor esté en línea, resolverá posibles problemas de red y que las funcionalidad sobre él estén operativas. Nuestro monitoreo 24/7 garantiza el continuo funcionamiento de nuestros servicios. Si deseas contratar nuestro servicio adicional de Backup lo puedes hacer por el 20% del costo de tu VPS, este
        incluye la creación de backups automáticos semanales y mensuales además de la opción de crear Snapshots.</p>
      </div>
    </div>
  </div>
</section>

<ng-container *ngIf="showFooter">
  <app-footer></app-footer>
</ng-container>

