import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { URL } from  '../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class ApiService {

    constructor(
        private http: HttpClient
    ) {}

    public findInvoice(formData) :Observable<any>{
        const url = URL.FIND_INVOICE;
        return this.http.post(url,formData);
    }
}
