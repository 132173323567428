import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-block-logo',
    templateUrl: './block-logo.component.html'
})
export class BlockLogoComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

}
