import { Component, ElementRef, HostListener, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { traceButton } from '../../services/button-trace';
import { IDataModalTemplate, IModalData } from '../../interfaces/promotions.interface';
import { AppType } from '../../../common/assets/website-data';
import { ScrollToClass } from '../../scrollTo.class';
@Component({
    selector: 'app-dialog-promo',
    templateUrl: './dialog-promo.component.html',
    styleUrls: ['./dialog-promo.component.scss']
})
export class DialogPromoComponent extends ScrollToClass implements OnInit {
    @Input() dataModal: IDataModalTemplate;
    @Input() appType: AppType;
    public logoWidth = '45px';

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: IModalData,
        private _el: ElementRef,
        private dialog: MatDialogRef<DialogPromoComponent>
    ) {
        super(_el);
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.logoWidth = window.innerWidth < 768 ? '40px' : '45px';
    }

    ngOnInit(): void {
        this.logoWidth = window.innerWidth < 768 ? '40px' : '45px';
    }

    public useButton(event: unknown): void {
        traceButton(event, 'scroll');
        const closingData = { status: false, prCode: null };
        if (this.dataModal.promoCode?.code) {
            closingData.prCode = this.dataModal.promoCode.code;
        }
        if (this.dataModal.button.scrollTo) {
            this.scrollToSection(this.dataModal.button.scrollTo);
        }
        this.dialog.close(closingData);
    }
}
