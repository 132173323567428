export const content_mx = {
    home: {
        planList: [
            {
                id: 1,
                price: '90',
                memory: '1 GB',
                vcpu: '1 vCPU',
                ssd: '20 GB',
                traffic: '1 TB',
                btn_text: 'contratar',
                btn_link: 'https://panel.opencloud.host/cart.php?a=add&pid=3&currency=5',
                btn_id: 'o-hpt-contratar1',
                label: false,
                isOpen: true
            }, {
                id: 2,
                price: '180',
                memory: '2 GB',
                vcpu: '1 vCPU',
                ssd: '50 GB',
                traffic: '2 TB',
                btn_text: 'contratar',
                btn_link: 'https://panel.opencloud.host/cart.php?a=add&pid=4&currency=5',
                btn_id: 'o-hpt-contratar2',
                label: false,
                isOpen: false
            }, {
                id: 3,
                price: '360',
                memory: '4 GB',
                vcpu: '2 vCPU',
                ssd: '80 GB',
                traffic: '4 TB',
                btn_text: 'contratar',
                btn_link: 'https://panel.opencloud.host/cart.php?a=add&pid=5&currency=5',
                btn_id: 'o-hpt-contratar3',
                label: true,
                isOpen: false
            }, {
                id: 4,
                price: '720',
                memory: '8 GB',
                vcpu: '4 vCPU',
                ssd: '160 GB',
                traffic: '5 TB',
                btn_text: 'contratar',
                btn_link: 'https://panel.opencloud.host/cart.php?a=add&pid=6&currency=5',
                btn_id: 'o-hpt-contratar4',
                label: false,
                isOpen: false
            }
        ],
        listOfPlans: [
            {
                text: 'Discos SSD <b>Sólidos</b>',
                icon: 'icon-sprite-ssd'
            }, {
                text: 'Procesador <b>XEON E7</b>',
                icon: 'icon-sprite-proc'
            }, {
                text: '<b>Red privada</b>',
                icon: 'icon-sprite-privat'
            }, {
                text: 'Monitoreo en <b>tiempo real</b>',
                icon: 'icon-sprite-realtime'
            }, {
                text: '<b>Panel de control</b>',
                icon: 'icon-sprite-settings'
            }, {
                text: 'Uptime <b>99,9%</b>',
                icon: 'icon-sprite-optime'
            }
        ],
        advantagesList: [
            {
                title: 'Conectividad',
                count: '2',
                sub: 'Conexiones',
                sub2: 'TIER 1'
            }, {
                title: 'Redundancia',
                count: '3',
                sub: 'Enlaces de',
                sub2: 'FIBRA'
            }, {
                title: 'RAID',
                count: '10',
                sub: 'Almacenamiento',
                sub2: 'DATOS'
            }, {
                title: 'Respaldo Energético',
                count: '12',
                sub: 'Hrs Autonomía',
                sub2: 'GENERADOR'
            }, {
                title: 'UPS',
                count: 'n+1',
                sub: 'Respaldo',
                sub2: 'Energético'
            }
        ],
        reviews: [
            {
                id: 1,
                // tslint:disable-next-line:max-line-length
                text: 'Con OpenCloud supe realmente lo que es calidad en el servicio al cliente, cuando tuve alguna consulta o problema ellos siempre atendieron mis casos en forma oportuna y con toda la informacion detallada, la plataforma es intuitiva y muy sencilla de usar, sin duda son un gran aliado en mi día a día, recomiendo sus servicios al 100%.',
                name: 'Raul Silva Bustamante',
                prof: 'CEO',
                img: 'reviews-raul-silva',
                link: 'Retroventas.cl',
                url: 'https://www.retroventas.cl'
            }
        ],
        checkList_1: [
            'Discos SSD Sólidos Nativos',
            'Conectividad directa con los países de Latinoamérica',
            'Procesador Intel Xeon E7'
        ],
        checkList_2: [
            'Panel de Control',
            'Modo Rescate',
            'Acceso vía consola',
            'Upgrades / Cloud Server escalable',
            'Estadísticas en tiempo real',
            'DNS personalizados'
        ],
        payment: {
            img_desktop_x1: '/assets/images/home/payment/formas-de-pago-mx-desktop@1x.png',
            img_desktop_x2: '/assets/images/home/payment/formas-de-pago-mx-desktop@2x.png',
            img_tablet_x2: '/assets/images/home/payment/formas-de-pago-mx-tablet@2x.png',
            img_tablet_x1: '/assets/images/home/payment/formas-de-pago-mx-tablet@1x.png',
            img_mobile_x1: '/assets/images/home/payment/formas-de-pago-mx-mobile@1x.png',
            img_mobile_x2: '/assets/images/home/payment/formas-de-pago-mx-mobile@2x.png'
        }
    },
    price: {
        infoList: [
            {
                text: '<b>Discos SSD Sólidos</b> con mayor velocidad y estabilidad',
                icon: 'icon-sprite-ssd'
            }, {
                text: '<b>Procesador XEON E7</b> para más poder y eficiencia en los procesos',
                icon: 'icon-sprite-proc'
            }, {
                text: '<b>3 Enlaces dedicados autónomos y redundantes</b> para una mayor estabilidad',
                icon: 'icon-sprite-canals'
            }, {
                text: '<b>Monitoreo en tiempo real</b> con sistema de alerta para tu Cloud Server',
                icon: 'icon-sprite-realtime'
            }, {
                text: '<b>Panel de control</b> para administrar libremente tu servidor virtual',
                icon: 'icon-sprite-settings'
            }, {
                text: '<b>Uptime 99.9%</b> en servicios, ping, HTTP y red',
                icon: 'icon-sprite-optime'
            }
        ]
    },
    features: {
        supportList: [
            {
                icon: 'icon-sprite-chat',
                text: 'Chat Online',
                count: 2,
                unit: 'min.'
            }, {
                icon: 'icon-sprite-call',
                text: 'Teléfono',
                count: 5,
                unit: 'min.'
            }, {
                icon: 'icon-sprite-fb',
                text: 'Redes Sociales',
                count: 5,
                unit: 'min.'
            }, {
                icon: 'icon-sprite-ticket',
                text: 'Ticket',
                count: 12,
                unit: 'hrs.'
            }
        ]
    },
    additional: {
        points1: [
            '<b>Backup automáticos</b> semanales y mensuales',
            '<b>Opción de Snapshot</b> para crear un respaldo instantáneo al momento',
            '<b>Historial disponible</b> de backups realizados',
        ],
        points2: [
            '<b>Uptime 99,9%</b> total anual',
            '<b>Almacenamos tus backups en un servidor dedicado</b> para asegurar la integridad de tus respaldos',
        ]
    },

    priceList: [
        {
            plan: 'Planes Estándar',
            // tslint:disable-next-line:max-line-length
            text: 'Planes de SSD Cloud Server equilibrados con una buena cantidad de memoria para alojar y escalar aplicaciones como blogs, aplicaciones web, entornos de prueba y bases de datos.',
            table_header: [
                'MEMORIA',
                'VCPUS',
                'SSD DISCO',
                'TRÁFICO',
                'PRECIO *',
                ''
            ],
            table: [
                {
                    memory: '1 GB',
                    vcpu: '1 vCPU',
                    ssd: '20 GB',
                    trafic: '1 TB',
                    price: '90',
                    isLabel: false,
                    btnId: 'o-ppt-contratar1',
                    btnLink: 'https://panel.opencloud.host/cart.php?a=add&pid=3&currency=5'
                }, {
                    memory: '2 GB',
                    vcpu: '1 vCPU',
                    ssd: '50 GB',
                    trafic: '2 TB',
                    price: '180',
                    isLabel: false,
                    btnId: 'o-ppt-contratar2',
                    btnLink: 'https://panel.opencloud.host/cart.php?a=add&pid=4&currency=5'
                }, {
                    memory: '4 GB',
                    vcpu: '2 vCPU',
                    ssd: '80 GB',
                    trafic: '4 TB',
                    price: '360',
                    isLabel: true,
                    btnId: 'o-ppt-contratar3',
                    btnLink: 'https://panel.opencloud.host/cart.php?a=add&pid=5&currency=5'
                }, {
                    memory: '8 GB',
                    vcpu: '4 vCPU',
                    ssd: '160 GB',
                    trafic: '5 TB',
                    price: '720',
                    isLabel: false,
                    btnId: 'o-ppt-contratar4',
                    btnLink: 'https://panel.opencloud.host/cart.php?a=add&pid=6&currency=5'
                }, {
                    memory: '16 GB',
                    vcpu: '6 vCPU',
                    ssd: '320 GB',
                    trafic: '6 TB',
                    price: '1.400',
                    isLabel: false,
                    btnId: 'o-ppt-contratar5',
                    btnLink: 'https://panel.opencloud.host/cart.php?a=add&pid=7&currency=5'
                }, {
                    memory: '32 GB',
                    vcpu: '8 vCPU',
                    ssd: '640 GB',
                    trafic: '7 TB',
                    price: '2.800',
                    isLabel: false,
                    btnId: 'o-ppt-contratar6',
                    btnLink: 'https://panel.opencloud.host/cart.php?a=add&pid=8&currency=5'
                },
                {
                    memory: '48 GB',
                    vcpu: '12 vCPU',
                    ssd: '960 GB',
                    trafic: '8 TB',
                    price: '4.400',
                    isLabel: false,
                    btnId: 'o-ppt-contratar7',
                    btnLink: 'https://panel.opencloud.host/cart.php?a=add&pid=56&currency=5'
                },
                {
                    memory: '64 GB',
                    vcpu: '16 vCPU',
                    ssd: '1280 GB',
                    trafic: '9 TB',
                    price: '5.900',
                    isLabel: false,
                    btnId: 'o-ppt-contratar8',
                    btnLink: 'https://panel.opencloud.host/cart.php?a=add&pid=9&currency=5'
                }, {
                    memory: '96 GB',
                    vcpu: '20 vCPU',
                    ssd: '1920 GB',
                    trafic: '10 TB',
                    price: '8.800',
                    isLabel: false,
                    btnId: 'o-ppt-contratar9',
                    btnLink: 'https://panel.opencloud.host/cart.php?a=add&pid=10&currency=5'
                }, {
                    memory: '128 GB',
                    vcpu: '24 vCPU',
                    ssd: '2560 GB',
                    trafic: '11 TB',
                    price: '11.700',
                    isLabel: false,
                    btnId: 'o-ppt-contratar10',
                    btnLink: 'https://panel.opencloud.host/cart.php?a=add&pid=11&currency=5'
                },
                {
                    memory: '192 GB',
                    vcpu: '32 vCPU',
                    ssd: '3840 GB',
                    trafic: '12 TB',
                    price: '17.600',
                    isLabel: false,
                    btnId: 'o-ppt-contratar11',
                    btnLink: 'https://panel.opencloud.host/cart.php?a=add&pid=57&currency=5'
                }
            ]
        },
        {
            plan: 'Planes CPU Dedicada',
            // tslint:disable-next-line:max-line-length
            text: 'Planes de VPS Cloud Server con CPU dedicada que trabajan de forma exclusiva para un solo cliente. Diseñados para una gran gama de proyectos ya que permiten bastantes instalaciones en su disco dependiendo de lo que el cliente requiera.',
            table_header: [
                'MEMORIA',
                'VCPUS',
                'SSD DISCO',
                'TRÁFICO',
                'PRECIO *',
                ''
            ],
            table: [
                {
                    memory: '4 GB',
                    vcpu: '2 vCPU',
                    ssd: '25 GB',
                    trafic: '6 TB',
                    price: '900',
                    isLabel: false,
                    btnId: 'o-pptc-contratar1',
                    btnLink: 'https://panel.opencloud.host/cart.php?a=add&pid=46&currency=5'
                }, {
                    memory: '8 GB',
                    vcpu: '4 vCPU',
                    ssd: '50 GB',
                    trafic: '7 TB',
                    price: '1.700',
                    isLabel: false,
                    btnId: 'o-pptc-contratar2',
                    btnLink: 'https://panel.opencloud.host/cart.php?a=add&pid=47&currency=5'
                }, {
                    memory: '16 GB',
                    vcpu: '8 vCPU',
                    ssd: '100 GB',
                    trafic: '8 TB',
                    price: '3.500',
                    isLabel: false,
                    btnId: 'o-pptc-contratar3',
                    btnLink: 'https://panel.opencloud.host/cart.php?a=add&pid=48&currency=5'
                }, {
                    memory: '32 GB',
                    vcpu: '16 vCPU',
                    ssd: '200 GB',
                    trafic: '9 TB',
                    price: '7.000',
                    isLabel: true,
                    btnId: 'o-pptc-contratar4',
                    btnLink: 'https://panel.opencloud.host/cart.php?a=add&pid=49&currency=5'
                }, {
                    memory: '64 GB',
                    vcpu: '32 vCPU',
                    ssd: '400 GB',
                    trafic: '10 TB',
                    price: '14.000',
                    isLabel: false,
                    btnId: 'o-pptc-contratar5',
                    btnLink: 'https://panel.opencloud.host/cart.php?a=add&pid=50&currency=5'
                }
            ]
        },
        {
            plan: 'Planes Alta Memoria',
            // tslint:disable-next-line:max-line-length
            text: 'Planes para los clientes más exigentes con grandes necesidades de Memoria RAM para el procesamiento de grandes cantidades de datos de forma simultánea. Diseñados para proyectos complejos y con altos requerimientos de memoria.',
            table_header: [
                'MEMORIA',
                'VCPUS',
                'SSD DISCO',
                'TRÁFICO',
                'PRECIO *',
                ''
            ],
            table: [
                {
                    memory: '16 GB',
                    vcpu: '1 vCPU',
                    ssd: '25 GB',
                    trafic: '6 TB',
                    price: '1.000',
                    isLabel: true,
                    btnId: 'o-pptm-contratar1',
                    btnLink: 'https://panel.opencloud.host/cart.php?a=add&pid=51&currency=5'
                }, {
                    memory: '32 GB',
                    vcpu: '2 vCPU',
                    ssd: '50 GB',
                    trafic: '7 TB',
                    price: '2.000',
                    isLabel: false,
                    btnId: 'o-pptm-contratar2',
                    btnLink: 'https://panel.opencloud.host/cart.php?a=add&pid=52&currency=5'
                }, {
                    memory: '64 GB',
                    vcpu: '4 vCPU',
                    ssd: '80 GB',
                    trafic: '8 TB',
                    price: '4.000',
                    isLabel: false,
                    btnId: 'o-pptm-contratar3',
                    btnLink: 'https://panel.opencloud.host/cart.php?a=add&pid=53&currency=5'
                }, {
                    memory: '128 GB',
                    vcpu: '8 vCPU',
                    ssd: '160 GB',
                    trafic: '9 TB',
                    price: '8.000',
                    isLabel: false,
                    btnId: 'o-pptm-contratar4',
                    btnLink: 'https://panel.opencloud.host/cart.php?a=add&pid=54&currency=5'
                }, {
                    memory: '192 GB',
                    vcpu: '16 vCPU',
                    ssd: '320 GB',
                    trafic: '10 TB',
                    price: '12.000',
                    isLabel: false,
                    btnId: 'o-pptm-contratar5',
                    btnLink: 'https://panel.opencloud.host/cart.php?a=add&pid=55&currency=5'
                }
            ]
        }
    ],
    discount: [
        {
            discount: '0%',
            sub: 'Mensual'
        }, {
            discount: '0%',
            sub: 'Trimestral'
        }, {
            discount: '0%',
            sub: 'Semestral'
        }, {
            discount: '10%',
            sub: 'Anual'
        }, {
            discount: '15%',
            sub: 'Bienal'
        }, {
            discount: '20%',
            sub: 'Trienal'
        }
    ],
    priceListExtended: [
        {
            id: 1,
            title: 'CPanel',
            price: '580'
        }
        , {
            id: 2,
            title: 'Cloud Linux',
            price: '200'
        }
        // , {
        //     id: 3,
        //     title: 'Trendy Site Builder',
        //     price: '200'
        // }
        , {
            id: 4,
            title: 'KernelCare',
            price: '50'
        }
        // , {
        //     id: 5,
        //     title: 'RVSkin',
        //     price: '50'
        // }
        , {
            id: 6,
            title: 'Softaculous',
            price: '25'
        }
        // , {
        //     id: 7,
        //     title: 'Installatron',
        //     price: '50'
        // }
        // , {
        //     id: 8,
        //     title: 'WHM Sonic',
        //     price: '50'
        // }
        // , {
        //     id: 9,
        //     title: 'RVSite Builder',
        //     price: '100'
        // }
        // , {
        //     id: 10,
        //     title: 'WHMXtra',
        //     price: '60'
        // }
    ]
};
