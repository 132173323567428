import { Component, OnInit } from '@angular/core';
import { LocaleService } from '../services/locale.service';
import { MetaService } from '@ngx-meta/core';
import { URL } from '../../environments/environment';

@Component({
    selector: 'app-additional',
    templateUrl: './additional.component.html'
})

export class AdditionalComponent implements OnInit {
    public URL = URL;
    locale;
    symbol = '';
    content;
    taxez: string;
    isSelectedBtn: boolean;
    isActiveTab1: boolean = true;

    isOpenInfoTable: number = 1;

    // lazy laoding images
    showImage1: boolean = false;
    showFooter: boolean = false;

    priceList;

    constructor(
        private localeService: LocaleService,
        private readonly _meta: MetaService
    ) {
        this.taxez = this.localeService.currentContact.taxez;
        this.locale = this.localeService.locale;
        this.symbol = this.localeService.symbol;
        this.content = this.localeService.getContent().additional;
        this.priceList = this.localeService.getContent().priceListExtended;
    }

    ngOnInit() {
        // Set Meta Tags
        if (this.locale === 'es-CO') {
            this._setMetaCO();
        } else if (this.locale === 'es-PE') {
            this._setMetaPE();
        } else if (this.locale === 'es-MX') {
            this._setMetaME();
        } else if (this.locale === 'es-AR') {
            this._setMetaAR();
        } else {
            this._setMetaCL();
        }
    }

    toggleOnTablet(id) {
        if (window.innerWidth <= 670) {
            this.isOpenInfoTable = id;
        }
    }

    private _setMetaCL() {
        this._meta.setTitle('Servicio Backup Adicional para SSD Cloud Server | OpenCloud Chile');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Servicio de Backup para tu servidor SSD Cloud Server garantizando el respaldo de tus datos en servidores de respaldo externos. Controla todos tu Backups desde tu Panel de Control sin tener que solicitar asistencia técnica.');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'backup cloud server, backup vps hosting, servicio backup para servidores, backup ssd, servicio backup Chile, respaldo de vps Chile');
        this._meta.setTag('author', 'OpenCloud');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.OPENCLOUD_WEBSITE + '/assets/images/og/og-additional.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Servicio de Backup para tu servidor SSD Cloud Server garantizando');
        this._meta.setTag('og:locale', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_ME');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }

    private _setMetaPE() {
        this._meta.setTitle('Servicio Backup Adicional para SSD Cloud Server | OpenCloud Perú');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Servicio de Backup para tu servidor SSD Cloud Server garantizando el respaldo de tus datos en servidores de respaldo externos. Controla todos tu Backups desde tu Panel de Control sin tener que solicitar asistencia técnica.');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'backup cloud server, backup vps hosting, servicio backup para servidores, backup ssd, servicio backup Perú, respaldo de vps Perú');
        this._meta.setTag('author', 'OpenCloud');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.OPENCLOUD_WEBSITE + '/assets/images/og/og-additional.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Servicio de Backup para tu servidor SSD Cloud Server garantizando');
        this._meta.setTag('og:locale', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_ME');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }

    private _setMetaCO() {
        this._meta.setTitle('Servicio Backup Adicional para SSD Cloud Server | OpenCloud Colombia');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Servicio de Backup para tu servidor SSD Cloud Server garantizando el respaldo de tus datos en servidores de respaldo externos. Controla todos tu Backups desde tu Panel de Control sin tener que solicitar asistencia técnica.');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'backup cloud server, backup vps hosting, servicio backup para servidores, backup ssd, servicio backup Colombia, respaldo de vps Colombia');
        this._meta.setTag('author', 'OpenCloud');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.OPENCLOUD_WEBSITE + '/assets/images/og/og-additional.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Servicio de Backup para tu servidor SSD Cloud Server garantizando');
        this._meta.setTag('og:locale', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_ME');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }

    private _setMetaME() {
        this._meta.setTitle('Servicio Backup Adicional para SSD Cloud Server | OpenCloud México');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Servicio de Backup para tu servidor SSD Cloud Server garantizando el respaldo de tus datos en servidores de respaldo externos. Controla todos tu Backups desde tu Panel de Control sin tener que solicitar asistencia técnica.');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'backup cloud server, backup vps hosting, servicio backup para servidores, backup ssd, servicio backup México, respaldo de vps México');
        this._meta.setTag('author', 'OpenCloud');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.OPENCLOUD_WEBSITE + '/assets/images/og/og-additional.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Servicio de Backup para tu servidor SSD Cloud Server garantizando');
        this._meta.setTag('og:locale', 'es_ME');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }

    private _setMetaAR() {
        this._meta.setTitle('Servicio Backup Adicional para SSD Cloud Server | OpenCloud Argentina');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Servicio de Backup para tu servidor SSD Cloud Server garantizando el respaldo de tus datos en servidores de respaldo externos. Controla todos tu Backups desde tu Panel de Control sin tener que solicitar asistencia técnica.');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'backup cloud server, backup vps hosting, servicio backup para servidores, backup ssd, servicio backup Argentina, respaldo de vps Argentina');
        this._meta.setTag('author', 'OpenCloud');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.OPENCLOUD_WEBSITE + '/assets/images/og/og-additional.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Servicio de Backup para tu servidor SSD Cloud Server garantizando');
        this._meta.setTag('og:locale', 'es_AR');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_ME');
    }

}
