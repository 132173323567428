import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PriceComponent } from './price/price.component';
import { AdditionalComponent } from './additional/additional.component';
import { FeaturesComponent } from './features/features.component';
import { ContactSupportWrapperComponent } from './contact-support-wrapper/contact-support-wrapper.component';
import { ContactWrapperComponent } from './contact/contact-wrapper.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { PaymentFormsWrapperComponent } from './payment-forms-wrapper/payment-forms-wrapper.component';
import { HomeComponentVps } from './home-vps/home-vps.component';
import { PriceVpsComponent } from './price-vps/price-vps.component';
import { AdditionalVpsComponent } from './additional-vps/additional-vps.component';
import { FeaturesVpsComponent } from './features-vps/features-vps.component';
import { SuccessComponent } from './success/success.component';
import { SuccessGuard } from './success/success.guard';
import { DirectPaymentComponent } from './direct-payment/direct-payment.component';


const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        pathMatch: 'full'
    }, {
        path: 'vps/.',
        component: HomeComponentVps,
        pathMatch: 'full'
    },  {
        path: 'success',
        component: SuccessComponent,
        canActivate: [SuccessGuard],
    }, {
        path: 'vps',
        redirectTo: 'vps/.',
        pathMatch: 'full'
    }, {
        path: 'vps/precios/.',
        component: PriceVpsComponent,
        pathMatch: 'full'
    }, {
        path: 'vps/precios',
        redirectTo: 'vps/precios/.',
        pathMatch: 'full'
    }, {
        path: 'ssd-cloud-server/precios/.',
        component: PriceComponent
    }, {
        path: 'ssd-cloud-server/precios',
        redirectTo: 'ssd-cloud-server/precios/.',
        pathMatch: 'full'
    }, {
        path: 'ssd-cloud-server/adicionales/.',
        component: AdditionalComponent
    }, {
        path: 'ssd-cloud-server/adicionales',
        redirectTo: 'ssd-cloud-server/adicionales/.',
        pathMatch: 'full'
    },  {
        path: 'vps/adicionales/.',
        component: AdditionalVpsComponent
    }, {
        path: 'vps/adicionales',
        redirectTo: 'vps/adicionales/.',
        pathMatch: 'full'
    },{
        path: 'ssd-cloud-server/caracteristicas/.',
        component: FeaturesComponent
    }, {
        path: 'ssd-cloud-server/caracteristicas',
        redirectTo: 'ssd-cloud-server/caracteristicas/.',
        pathMatch: 'full'
    }, {
        path: 'vps/caracteristicas/.',
        component: FeaturesVpsComponent
    }, {
        path: 'vps/caracteristicas',
        redirectTo: 'vps/caracteristicas/.',
        pathMatch: 'full'
    },{
        path: 'contactar-con-soporte/.',
        component: ContactSupportWrapperComponent
    }, {
        path: 'contactar-con-soporte',
        redirectTo: 'contactar-con-soporte/.',
        pathMatch: 'full'
    }, {
        path: 'contactar-con-ventas/.',
        component: ContactWrapperComponent
    }, {
        path: 'contactar-con-ventas',
        redirectTo: 'contactar-con-ventas/.',
        pathMatch: 'full'
    }, {
        path: 'formas-de-pago/.',
        component: PaymentFormsWrapperComponent
    }, {
        path: 'formas-de-pago',
        redirectTo: 'formas-de-pago/.',
        pathMatch: 'full'
    },
    {
        path: 'success',
        component: SuccessComponent,
    },
    {
        path: 'pago-directo',
        redirectTo: 'pago-directo/.',
        pathMatch: 'full'
    },
    {
        path: 'pago-directo/.',
        component: DirectPaymentComponent
    },
    {
        path: '**',
        component: ErrorPageComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
