<section class="tab__wrapper">
  <div class="container">
    <div class="sup_title center upper" i18n>Expande tu servicio</div>
    <div class="title1 center" i18n><b>Adicionales</b></div>

    <div class="tabe">
      <div class="tabe__nav">
        <button [class.active]="isActiveTab1" mat-button (click)="isActiveTab1 = true" i18n>Backup</button>
        <button [class.active]="!isActiveTab1" mat-button (click)="isActiveTab1 = false" i18n>Licencias</button>
      </div>

      <div class="tabe__content">

        <ng-container *ngIf="isActiveTab1">
          <div class="inner">
            <div class="sup_title center upper" i18n>Servicio backup</div>
            <h2 class="title2 center" i18n><b>Asegura</b> tus datos</h2>
            <p class="center" i18n>Contratando nuestro servicio de Backup se crearán respaldos semanales y mensuales de tus datos de forma automática y además podrás crear un snapshot para restaurar tus datos de ese momento.</p>

            <!-- START cardDiscount -->
            <div class="cardDiscount">
              <div class="cardDiscount__card">
                <span class="sub" i18n><b>Costo</b></span>
                <span class="title" i18n>Servicio de Backup</span>
                <span class="sub" i18n><b>Es el equivalente al:</b></span>
              </div>
              <div class="cardDiscount__card">
                <span class="title">20%</span>
                <span class="sub" i18n>DEL COSTO DE TU</span>
                <span class="sub" i18n><b>CLOUD SERVER</b></span>
              </div>
            </div>
            <!-- END cardDiscount -->

            <h3 class="title3" [preRender]="false" (deferLoad)="showImage1 = true" i18n>El servicio Backup Incluye</h3>

            <div class="flex-column">
              <ul class="checkList column">
                <li *ngFor="let point of content.points1">
                  <span [innerHtml]="point" i18n></span>
                  <ng-container *ngIf="showImage1"><!-- showImage1 because we need render before. -->
                    <div class="icon aceent_red">
                      <svg-icon-sprite
                        [src]="'/assets/images/sprites/sprite.svg#icon-check-around'"
                        [width]="'24px'"
                        [classes]="'icon-check-around'">
                      </svg-icon-sprite>
                    </div>
                  </ng-container>
                </li>
              </ul>

              <ul class="checkList column">
                <li *ngFor="let point of content.points2">
                  <span [innerHtml]="point" i18n></span>
                  <ng-container *ngIf="showImage1"><!-- showImage1 because we need render before. -->
                    <div class="icon aceent_red">
                      <svg-icon-sprite
                        [src]="'/assets/images/sprites/sprite.svg#icon-check-around'"
                        [width]="'24px'"
                        [classes]="'icon-check-around'">
                      </svg-icon-sprite>
                    </div>
                  </ng-container>
                </li>
              </ul>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="!isActiveTab1">
          <div class="sup_title center upper" i18n>Servicio licencia</div>
          <h2 class="title2 center" i18n><b>Expande</b> tu servicio</h2>
          <p class="center" i18n>Tenemos una gran variedad de licencias que te ayudarán a ampliar las posibilidades para tu servicio y negocio.</p>

          <div class="tablePrice2">
            <div class="tablePrice2__row" [class.open]="isOpenInfoTable === item.id" *ngFor="let item of priceList" (click)="toggleOnTablet(item.id)">
              <div class="tablePrice2__cell column">
                <div class="wrap">
                  <span i18n><b>{{item.title}}</b></span>
                  <span *ngIf="item.title == 'CPanel'"  class="sub" >Valor mensual {{taxez}},  desde</span>
                  <span *ngIf="item.title != 'CPanel'"  class="sub" i18n>Valor mensual + IVA</span>
                </div>
                <button mat-icon-button><mat-icon>keyboard_arrow_down</mat-icon></button>
              </div>
              <div class="tablePrice2__cell"><b><span>{{symbol}}</span> {{item.price}}</b></div>
            </div>
          </div>

        </ng-container>

      </div>
    </div>

  </div>
</section>

<section class="textColumn__wrapper" [preRender]="false" (deferLoad)="showFooter = true">
  <div class="container">
    <h2 class="title2 center" i18n><b>Preguntas frecuentes</b></h2>

    <div class="textColumn">
      <div class="textColumn__column">
        <h3 class="title3" i18n>¿Cuál es la frecuencia de los respaldos?</h3>
        <p i18n>Los respaldos se realizan de forma semanal y mensual con una retención por periodo específica.</p>
        <h3 class="title3" i18n>¿Puedo realizar Snapshot o Backup personalizado?</h3>
        <p i18n>Sí, se puede hacer un snapshot o backup personalizado en cualquier momento. Al realizar un snapshot se elimina el snapshot anterior.</p>
      </div>
      <div class="textColumn__column">
        <h3 class="title3" i18n>¿Dónde se almacenan los backups?</h3>
        <p i18n>Los backups son almacenados en servidores de backups externos especiales para estos respaldos</p>
        <h3 class="title3" i18n>¿Qué garantía entregan los backups?</h3>
        <p i18n>99% de las situaciones la restauración será exitosa. Te recomendamos realizar un snapshot antes de restaurar un backup. Recomendamos siempre tener un backup adicional para garantizar así un 100% de seguridad.</p>
      </div>
    </div>
  </div>
</section>

<ng-container *ngIf="showFooter"><!-- showFooter because we need render before. -->
  <app-footer></app-footer>
</ng-container>

